import { selectToken } from './../../store/auth/auth.selectors';
import { FILE_NAME, ZACE } from './../../util/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIResponse } from 'src/app/model/Response.model';
import { BASE_CONFIG } from 'src/app/util/base-settings';
import { SERVICE_URL } from 'src/app/util/constants';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../logger/logger.service';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private BASE_URL: string = environment.apiUrl;
  dataSubject = new BehaviorSubject(null);
  token: string = null
  

  constructor(
    private httpClient: HttpClient,
    private logger: LoggerService,
    private store: Store
  ) {}

 async makeHttpGetRequest(
    serviceURL: string,
    requestBody: any | null,
    isResponseType?: boolean,
    pToken?: string

  ): Promise<any> {
    let retValue;

    try {
      if (BASE_CONFIG.IS_DEBUG) console.log('makeHttpGetRequest');
      let token;
      if(pToken){
        token=pToken;
      }else{
        const sToken = localStorage.getItem('token');
        token=JSON.parse(sToken)['token'];
      }

      // this.dataSubject.next(true);
      // if (
      //   serviceURL == SERVICE_URL.APP_AUTH_URL
      // ) {
      //   return this.httpClient.get(
      //             this.BASE_URL + serviceURL,
                
      //             { headers: this.getHttpHeader()}
      //           ).pipe(map((response: any) => {  
      //             this.dataSubject.next(false);
      //             return response; 
      //           }) 
      //         );
      // }else{
      let responseType = null
        responseType = isResponseType?'blob':'json'
     
      let tokenHeader = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' +token,
        'Accept-Language': BASE_CONFIG.LANGUAGE,
        orgid: BASE_CONFIG.orgId,
      });

      
      retValue=  await this.httpClient.get(
                this.BASE_URL + serviceURL,
             
                { headers: tokenHeader, responseType: responseType}
              ).toPromise();
         

    // }
    
     
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HTTP_SERVICE,
        'error in makeHttpGetRequest',
        err.toString(),
        'makeHttpGetRequest'
      );
      this.logger.log(logRequest);
    }
    return retValue;
  }
  makeHttpPostRequest(
    serviceURL: string,
    requestBody: any | null,
    isResponseType?: boolean,
    pToken?: string
  ): Observable<APIResponse<any>> {
    
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log('makeHttpPostRequest');
      if(pToken){
        this.token = pToken
      }else{
        // let sToken = this.store
        // .select(selectToken())
        // .subscribe((token: string) => {
        //   if (token) {
        //     this.token = token
        //   }
        // })
        this.setToken();
    }
      this.dataSubject.next(true);
      if (
        serviceURL == SERVICE_URL.APP_AUTH_URL
      ) {
        return this.httpClient.post(
                  this.BASE_URL + serviceURL,
                  requestBody,
                  { headers: this.getHttpHeader()}
                ).pipe(map((response: any) => {  
                  this.dataSubject.next(false);
                  return response; 
                }) 
              );
      }else{
      let responseType = null
        responseType = isResponseType?'blob':'json'
     
      let tokenHeader = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' +this.token,
        'Accept-Language': BASE_CONFIG.LANGUAGE,
        orgid: BASE_CONFIG.orgId,
      });

      
            return this.httpClient.post(
                (serviceURL != SERVICE_URL.API_SEND_EMAIL ? this.BASE_URL : ZACE.BASE_URL) + serviceURL,
                requestBody,
                { headers: tokenHeader, responseType: responseType}
              ).pipe(map((response: any) => {  
                this.dataSubject.next(false);
                return response; 
              }) )
         

    }
    
     
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HTTP_SERVICE,
        'error in makeHttpPostRequest',
        err.toString(),
        'makeHttpPostRequest'
      );
      this.logger.log(logRequest);
    }
  }


  getHttpHeader(): HttpHeaders  {
  
      return  new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": BASE_CONFIG.LANGUAGE
    });
    
  }
  

  setToken()
  {
    try
    {
      const currentUser = firebase.auth().currentUser;

      if (currentUser)
      {
        const tokenPromise = (async () => await currentUser.getIdToken())();
        from(tokenPromise)
          .subscribe((token) =>
          {
            this.token = token;
          });
      }
      else 
      {
        const tokenStore$ = this.store.select(selectToken())
          .subscribe((token) =>
          {
            this.token = token;
          });

        tokenStore$.unsubscribe();
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HTTP_SERVICE,
        'error in setToken',
        err.toString(),
        'setToken'
      );
      this.logger.log(logRequest);
    }
  }


  
}
