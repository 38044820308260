import { Injectable } from '@angular/core';
import { addDays, format, subDays } from 'date-fns';
import { LoggerService } from "../services/logger/logger.service";
import { DATE_TIME_FORMAT, FILE_NAME } from "./constants";
import { BASE_CONFIG } from './base-settings';
import { UtilFunctions } from './util';

@Injectable({
  providedIn : "root"
})
export class DateFunctions {

  constructor(
    private logger : LoggerService,
    private util : UtilFunctions
  ) {}

/// returns formatted  String from Date Object
    formatDate(pDate, pFormat): string
    {
      let returnValue;

      try
      {
        let dateVal;
        if (pDate != null)
        {
          if (pDate instanceof Date)
          {
            dateVal = pDate;
          } else
          {
            dateVal = new Date(pDate);
          }
          if (dateVal instanceof Date && pFormat)
          {
            returnValue = format(dateVal, pFormat);
            //   returnValue = moment(dateVal).format(pFormat);
          }
        }
        else
        {
          returnValue = null;
        }
      } catch (err)
      {
        let body = this.logger.buildRequest(
          FILE_NAME.DATE_FUNCTIONS,
          "error in formatDate",
          err.toString(),
          "formatDate()",
        );
        this.logger.log(body);
      }

      return returnValue;
    }

    getLast6Months()
    {
        let retValue: string = '';
        try
        {
            let date=new Date();
            date.setMonth(date.getMonth() - 6);
            retValue = this.formatDate(date, DATE_TIME_FORMAT.API_DATE_FORMAT);
        }
        catch (error)
        {
            let logRequest = this.logger.buildRequest(
                FILE_NAME.DATE_FUNCTIONS,
                "error in getLast6Months",
                error.toString(),
                "getLast6Months"
            );
            this.logger.log(logRequest);
        }
        return retValue;
    }

    getLast5Months()
    {
        let retValue: string = '';
        try
        {
            let date=new Date();
            date.setMonth(date.getMonth() - 5);
            retValue = this.formatDate(date, DATE_TIME_FORMAT.API_DATE_FORMAT);
        }
        catch (error)
        {
            let logRequest = this.logger.buildRequest(
                FILE_NAME.DATE_FUNCTIONS,
                "error in getLast5Months",
                error.toString(),
                "getLast5Months"
            );
            this.logger.log(logRequest);
        }
        return retValue;
    }

    getNext3Months(pDate?: string)
    {
        let retValue: string = '';
        try
        {
          let date = pDate? new Date(pDate) : new Date();
            date.setMonth(date.getMonth() + 3);
            retValue = this.formatDate(date, DATE_TIME_FORMAT.API_DATE_FORMAT);
        }
        catch (error)
        {
          this.util.logFunc('getNext3Months', error, FILE_NAME.DATE_FUNCTIONS);
        }
        return retValue;
    }

    getLast3Months(pDate?: string)
    {
        let retValue: string = '';
        try
        {
          let date = pDate? new Date(pDate) : new Date();
            date.setMonth(date.getMonth() - 3);
            retValue = this.formatDate(date, DATE_TIME_FORMAT.API_DATE_FORMAT);
        }
        catch (error)
        {
          this.util.logFunc('getLast3Months', error, FILE_NAME.DATE_FUNCTIONS);
        }
        return retValue;
    }

    getLast5Years()
    {
        let retValue: string = '';
        try
        {
            let date=new Date();
            date.setFullYear(date.getFullYear() - 5);
            retValue = this.formatDate(date, DATE_TIME_FORMAT.API_DATE_FORMAT);
        }
        catch (error)
        {
            let logRequest = this.logger.buildRequest(
                FILE_NAME.DATE_FUNCTIONS,
                "error in getLast5Years",
                error.toString(),
                "getLast5Years"
            );
            this.logger.log(logRequest);
        }
        return retValue;
    }

    getLastYear()
    {
      let retValue: string = '';
      try {
        let date=new Date();
        date.setFullYear(date.getFullYear() - 1);
        retValue = this.formatDate(date, DATE_TIME_FORMAT.API_DATE_FORMAT);
      } catch (error) {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.DATE_FUNCTIONS,
          "error in getLastYear",
          error.toString(),
          "getLastYear"
        );
        this.logger.log(logRequest);
      }
      return retValue;
    }

    getNextYear()
    {
      let retValue: string = '';
      try {
        let date=new Date();
        date.setFullYear(date.getFullYear() + 1);
        retValue = this.formatDate(date, DATE_TIME_FORMAT.API_DATE_FORMAT1);
      } catch (error) {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.DATE_FUNCTIONS,
          "error in getNextYear",
          error.toString(),
          "getNextYear"
        );
        this.logger.log(logRequest);
      }
      return retValue;
    }

    getLast30Days()
    {
      let retValue: string = '';
      try
      {
        retValue = this.formatDate(subDays(new Date(), 30), DATE_TIME_FORMAT.API_DATE_FORMAT1);
      }
      catch (error)
      {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.DATE_FUNCTIONS,
          "error in getLast30Days",
          error.toString(),
          "getLast30Days"
        );
        this.logger.log(logRequest);
      }
      return retValue;
    }
    getNext30Days()
    {
      let retValue: string = '';
      try
      {
        retValue = this.formatDate(addDays(new Date(), 30), DATE_TIME_FORMAT.API_DATE_FORMAT1);
      }
      catch (error)
      {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.DATE_FUNCTIONS,
          "error in getLast30Days",
          error.toString(),
          "getLast30Days"
        );
        this.logger.log(logRequest);
      }
      return retValue;
    }
    getStartAndEndDateOfMonth(pDate)
    {
        let retValue ;
        try {
            if(BASE_CONFIG.IS_DEBUG)    console.log('getStartAndEndDateOfMonth');
            if(pDate)
            {
                var firstDay = new Date(pDate.getFullYear(), pDate.getMonth(), 1);
                var lastDay = new Date(pDate.getFullYear(), pDate.getMonth() + 1, 0);
                retValue = { start : firstDay , end : lastDay };
            }
        } catch (error) {
            let logRequest = this.logger.buildRequest(
                FILE_NAME.DATE_FUNCTIONS,
                "error in getStartAndEndDateOfMonth",
                error.toString(),
                "getStartAndEndDateOfMonth",
            
            );
            this.logger.log(logRequest);
        }
        return retValue ;
    }
  

    convertMinToTime(pTimeInMinutes: number): any
    {
      let retValue: any = {
        time: '',
        date: ''
      };
      try
      {
        const MINUTES = pTimeInMinutes;

        const min = MINUTES % 60;
        const hour = (MINUTES - min) / 60;

        let HH = String(hour).padStart(2, '0'); // (or alternatively) h = h < 10 ? '0' + h : h;
        let MM = String(min).padStart(2, '0');  // (or alternatively) m = m < 10 ? '0' + m : m;

        let timeFormat = `${HH} hours ${MM} minutes`;
        let dateFormat = `2022-01-01T${HH}:${MM}:00`; // for default value hh:MM only dynamic

        retValue.time = timeFormat;
        retValue.date = dateFormat;
      }
      catch (err)
      {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.DATE_FUNCTIONS,
          "error in convertMinToTime",
          err.toString(),
          "convertMinToTime",
        );
        this.logger.log(logRequest);
      }
      return retValue;
    }

    getTimeToMins(pTime: string, days: number = 0): any
    {
      let retValue: number = 0;
      try
      {
        let timeValue = pTime.split('T')[1];
        let hms = timeValue.split(':');

        let min = (+hms[0]) * 60 + (+hms[1]);

        if (days > 0)
        {
          min += 1440 * days;
        }

        retValue = min;
      }
      catch (err)
      {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.DATE_FUNCTIONS,
          "error in getTimeToMins",
          err.toString(),
          "getTimeToMins",
        );
        this.logger.log(logRequest);
      }
      return retValue;
    }

    convertMinToDays(pTimeInMinutes: number): any
    {
      let retValue: any = {
        time: '',
        date: '',
        days: 0
      };

      try
      {
        let MINUTES = pTimeInMinutes;

        let days = Math.floor(MINUTES / 24 / 60);

        let min = MINUTES % 60;
        let hour = Math.abs(MINUTES - (days * 1440) - min) / 60;


        let HH = String(Math.floor(hour)).padStart(2, '0'); // (or alternatively) h = h < 10 ? '0' + h : h;
        let MM = String(Math.floor(min)).padStart(2, '0');  // (or alternatively) m = m < 10 ? '0' + m : m;

        let timeFormat = `${HH} hours ${MM} minutes`;
        let dateFormat = `2022-01-01T${HH}:${MM}:00`; // for default value hh:MM only dynamic

        retValue.time = timeFormat;
        retValue.date = dateFormat;
        retValue.days = days;
      }
      catch (err)
      {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.DATE_FUNCTIONS,
          "error in convertMinToDays",
          err.toString(),
          "convertMinToDays",
        );
        this.logger.log(logRequest);
      }
      return retValue;
    }

    getNextDate(pDate?: string)
    {
      let retValue: string = '';
      try {
        let date = pDate? new Date(pDate) : new Date();
        date.setDate(date.getDate() + 1);
        retValue = this.formatDate(date, DATE_TIME_FORMAT.COMMON_DATE_FORMAT2);
      } catch (error) {
        this.util.logFunc('getNextDate',error,FILE_NAME.DATE_FUNCTIONS);
      }
      return retValue;
    }

    getPrevDate(pDate?: string)
    {
      let retValue: string = '';
      try {
        let date = pDate? new Date(pDate) : new Date();
        date.setDate(date.getDate() - 1);
        retValue = this.formatDate(date, DATE_TIME_FORMAT.API_DATE_FORMAT1);
      } catch (error) {
        this.util.logFunc('getPrevDate',error,FILE_NAME.DATE_FUNCTIONS);
      }
      return retValue;
    }

    dateTimeToLocal(dateTime:Date,whichDate:number)
    {
      try
      {
        let hour=0,min=0,sec=0;//fromDate
        if(whichDate==2)//toDate
          {
            hour=23;
            min=59;
            sec=59;
          }
        let d=new Date(dateTime.getFullYear(),dateTime.getMonth(),dateTime.getDate(),hour,min,sec,0);
        return (this.formatDate(d,DATE_TIME_FORMAT.API_DATE_FORMAT));
      }
      catch (err)
      {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.DATE_FUNCTIONS,
          "error in getTimeToMins",
          err.toString(),
          "getTimeToMins",
        );
        this.logger.log(logRequest);
      }
    }

}