// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appName: 'br',
  envName: 'PROD',
  apiVersion: 'v1',
  logAppId: 'b2b.ui',
  version: '3.0.4',
  orgId: '80a9c00e-a785-418a-9b38-19ea1ce7a488',
  apiUrl: 'https://auth.api.ascent24.io/',
  loggerUrl: 'https://b2bapplog.ascent24.io/b2b.ui',
  paymentApiKey: 'htWetleCWHaLpCeuzxdTnOJEePtPQTrJ',
  isAdmin: false,
  firebaseConfig: {
    apiKey: "AIzaSyDrf3oH4az9M1vn-UjR7bE10sPnupeKXXY",
  authDomain: "billsreminder-auth.ascent24.io",
  databaseURL: "https://auth-test-project-90609.firebaseio.com",
  projectId: "auth-test-project-90609",
  storageBucket: "auth-test-project-90609.appspot.com",
  messagingSenderId: "1026523308150",
  appId: "1:1026523308150:web:2dec2e5abf1e5fc1433f57"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
