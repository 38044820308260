import { Injectable } from "@angular/core";

import { Observable, map } from "rxjs";
import { APIResponse } from "src/app/model/Response.model";
import { BASE_CONFIG } from "src/app/util/base-settings";
import { FILE_NAME, SERVICE_URL } from "src/app/util/constants";
import { LoggerService } from "../logger/logger.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    constructor(private logger: LoggerService, private httpClient:HttpClient ) { }

    deviceUpdate(pRequestBody): Observable<APIResponse<any>>
    {
        let retValue: Observable<APIResponse<any>>;
        try {
            if (BASE_CONFIG.IS_DEBUG) console.log("deviceUpdate");
            retValue= this.httpClient.post(
                SERVICE_URL.APP_DEVICE_UPDATE,
                pRequestBody,
                { headers: this.getHttpHeader()}
              ).pipe(map((response: any) => {  
                return response; 
              }) 
            );
        } catch (error) {
            let logRequest = this.logger.buildRequest(
                FILE_NAME.SETTINGS_SERVICE,
                "error in deviceUpdate",
                error.toString(),
                "deviceUpdate"
            );
            this.logger.log(logRequest);
        }
        return retValue;
    }
    getExchangeRate(pFromCurrency): Observable<APIResponse<any>>
    {
        let retValue: Observable<APIResponse<any>>;
        try {
            if (BASE_CONFIG.IS_DEBUG) console.log("getExchangeRate");
           let headers =  new HttpHeaders({
            Accept: "application/json",
            "Content-Type": "multipart/form-data"
        
          //   'Access-Control-Allow-Origin': '*',
        });
            retValue= this.httpClient.get(
                SERVICE_URL.GET_EXCHANGE_RATES+pFromCurrency,
                { headers: headers}
              
              ).pipe(map((response: any) => {  
                return response; 
              }) 
            );
        } catch (error) {
            let logRequest = this.logger.buildRequest(
                FILE_NAME.SETTINGS_SERVICE,
                "error in getExchangeRate",
                error.toString(),
                "getExchangeRate"
            );
            this.logger.log(logRequest);
        }
        return retValue;
    }
    getHttpHeader(): HttpHeaders  {
  
        return  new HttpHeaders({
          Accept: "application/json",
          "Content-Type": "application/json",
          "Accept-Language": BASE_CONFIG.LANGUAGE,
        //   'Access-Control-Allow-Origin': '*',
      });
   
    }
}