
export class COMPONENT_NAME {

  static readonly APP_COMPONENT = "app.component";
  static readonly UTIL = 'util.ts'
  static readonly MY_ACCOUNT_PAGE = 'my-account.component.ts'
  static readonly CUSTOM_MODAL_COMPONENT = 'custom-modal-component.ts'
  static readonly ERROR_ALERT_MODAL_COMPONENT = 'error-alert-modal-component.ts'
  static readonly DATE_SELECTOR_COMPONENT = 'date-selector.component.ts'
  static readonly ION_DATE_TIME_COMPONENT = 'ion-date-time-picker.component.ts'
  static readonly IMAGE_GALLERY = 'image-gallery.component.ts'
  static readonly SLIDES_COMPONENT = 'slides.component.ts'
  static readonly NEW_TAX_COMPONENT = 'new-tax.component.ts'
  static readonly ADD_TERMS_COMP = 'add-terms.component.ts'
  static readonly DATA_TABLE_COMP = 'data-table.component.ts'
  static readonly ADD_PAYMENT_METHOD_COMP = 'add-payment-method.component.ts'
  static readonly TEXT_AREA_COMP = 'text-area.component.ts'
  static readonly HEADER_COMP = 'header.component.ts'
  static readonly TABS_COMP = 'tabs.component.ts'
  static readonly CURRENCY_COMPONENT = 'currency.component.ts'
  static readonly C_DROPDOWN_COMPONENT = 'select-component.component.ts'
  static readonly ION_DATE_TIME_PICKER = 'ion-date-time-picker.component.ts'
  static readonly FILTER_COMPONENT = 'filter.component.ts'
  static readonly FILE_POND_COMPONENT = 'filepond.component.ts'
  static readonly DRAGGABLE_SLIDER_COMPONENT = 'draggable-slider.component.ts'
  static readonly PDF_PREVIEW_COMPONENT = 'pdf-preview.component.ts'
  static readonly FILE_UPLOAD_COMPONENT = 'file-upload.component.ts'
  static readonly IMAGE_EDIT_COMPONENT = 'image-edit.component.ts'
  static readonly IMAGE_CROP_COMPONENT = 'image-crop.component.ts'
  static readonly CHECK_BOX_COMPONENT = 'check-box-component.component.ts'
  static readonly SEARCH_COMPONENT = 'search.component.ts'
  static readonly EXPORT_PDF = 'export-pdf.component.ts'
  static readonly SIGNATURE_COMPONENT = 'signature.component.ts'
  static readonly CHARTS_COMPONENT = 'charts.component.ts'
  static readonly ABOUT_US_COMPONENT = 'about-us.component.ts'
  static readonly ANIMATION_COMPONENT = 'animation.component.ts'
  static readonly INTRO_SLIDES_COMPONENT = 'intro-slides.component.ts'
  static readonly ADD_CATEGORY_COMPONENT = 'add-category.component.ts'
  static readonly ION_DATEPICKER_COMPONENT = 'ion-datepicker.component.ts'
  static readonly RANGE_DATE_PICKER_COMPONENT = 'range-date-picker.component.ts'
  static readonly GUEST_USER_MODEL_COMPONENT = "guest_user_model.component.ts";
  static readonly BILL_LIST_COMPONENT = 'bill-list.component.ts'
  static readonly SELECT_SEARCHABLE_COMPONENT = 'select-searchable.component.ts';
  static readonly SELECT_COMPONENT = 'select.component.ts';
  static readonly FORM_ERRORS_COMPONENT = 'form-errors.component.ts';
  static readonly OCR_COMPONENT = 'ocr.component.ts';

  //pages
  static readonly CLEAN_CALC = 'clean-calc.page.ts'; 
  static readonly EMI_CALC = 'emi-calc.page.ts'; 
  static readonly CI = 'compound-interest.page.ts'; 
  static readonly CURRENCY_CONVERTER = 'currency-converter.page.ts'

  static readonly FEEDBACK_PAGE = 'feedback.page.ts';

  static readonly HOME_PAGE = 'home.page.ts'
  static readonly FAQS_PAGE = 'faqs.page.ts';


  static readonly LOGIN_PAGE = 'login.page.ts'

  static readonly REPORT_PAGE = 'report.page.ts'
  static readonly ADD_BILL_PAGE = 'add-bill.page.ts'
  static readonly BILLS_LIST_PAGE = 'bills-list.page.ts'
  static readonly SETTINGS_PAGE = 'settings.page.ts'
  static readonly DASHBOARD_PAGE = 'dashboard.page.ts'
  static readonly ABOUT_PAGE = 'about.page.ts'
  static readonly LOGIN_GUARD = 'login.guard.ts'
  static readonly GUIDE_PAGE = 'guide.page.ts';
  static readonly CALENDAR_OVERVIEW = 'calendar-overview.ts';
  static readonly DELETE_PAGE = 'delete.page.ts';
}

export class FILE_NAME {

  static readonly UTIL = 'util.ts'
  static readonly DATE_FUNCTIONS = 'date-functions.ts'
  static readonly MEDIA_FUNCTIONS = 'media-functions.ts'
  static readonly API_VALIDATE = 'api-validate.ts'
  static readonly CLEAR_STORE = 'clearStore.ts'
  static readonly QUERY = 'query.ts'

  static readonly DATE_AGO_PIPE = 'date-ago.pipe.ts'

//services
  static readonly BILLS_SERVICE = 'bills.service.ts'
  static readonly DATE_PICKER_COMPONENT = 'date-picker.component.ts'
  static readonly USER_AUTHENTICATION_SERVICE = 'user-authentication.service.ts'
  static readonly AUTH_SERVICE = 'auth-service.ts'
  static readonly HTTP_SERVICE = 'http-service.ts'
  static readonly SETTINGS_SERVICE = 'settings.service.ts'
  static readonly FAQ_SERVICE = 'faq.service.ts'

  //directives
  static readonly CATEGORY_COLOR_DIRECTIVE = 'payment-color.directive.ts';
}
  export class SOCIAL_LOGIN_TYPE
{
  static readonly GOOGLE = "google";
  static readonly TWITTER = "twitter";
  static readonly FACEBOOK = "facebook";
  static readonly APPLE = "apple";
}

  
  export class PATHNAME {
  static readonly HOME = '/'
  static readonly LOGIN = 'auth/login'
  static readonly MY_ACCOUNT = 'profile'
  static readonly PAYMENT = 'payment'
  }
  
  
  export class SERVICE_URL {
    static readonly APP_AUTH_URL = 'api/auth?appid=aba23-32njhf'
   static readonly APP_DEVICE_UPDATE='https://remindersvc.api.ascent24.io/api/v1/update_UserDevices';
   static readonly GET_EXCHANGE_RATES='https://v6.exchangerate-api.com/v6/846b5c3d5f212814648929a7/latest/';
   static readonly API_SEND_EMAIL = 'api/public/email';
  }
  
  export class ROUTER_PARAM {
    static SESSION_ID = "s";
    static PARAM = "param";
    static REDIRECT_URL = "redirect-url";
    static INTRO = 'intro';
  }
  
  export class FB_AUTH_TYPE {
    static SIGNIN_WITH_POPUP = "signInWithPopup";
    static SIGNIN_WITH_REDIRECT = "signInWithRedirect";
    static SIGNIN_WITH_EMAIL_AND_PW="signInWithEmailAndPassword";
  
  }
  
  export class HttpHeader {
    static readonly authorization: string = "Authorization";
  }

  export class AUTH_KEY {
    static readonly  ok = "OK";
    static readonly  error = "ERROR";
    static readonly  existingUser = "existinguser-norefresh";
    static readonly  tokenExpired = "token-expired";
    static readonly  newUserRefresh = "newuser-refresh";
  }
  
  export class HTTP_CODES {
    static ERROR_JWT = "jwt";
    static ERROR_JWT_01 = "JWT01";
    static ERROR_JWT_02 = "JWT02";
    static ERROR_PER = "PER01";
    static ERROR_AUT2 = "AUT2";
    static SUBSCRIPTION_FAILED = "start-failed";
    static ERROR_INVOICES_PAID="PST03";
    static readonly   SUCCESS = 200;
    static readonly   ERROR = 400;
    static  readonly SUCCESS_STR = "Success";
    static  readonly  ERROR_STR = "Error";
    static readonly  ERROR_NET = "Connectivity";
  
  }
  
 
  export class PLATFORM {
    static android = "android";
    static ios = "ios";
    static web = 'web';
    static mac = 'mac';
  }
  
 
  
  export enum PurchaseErrorCodes
  {
    ITEM_ALREADY_OWNED = 6,
    OPERATION_ALREADY_IN_PROGRESS = 15,
    STORE_PROBLEM = 2,
    NETWORK_ERROR = 10,
    PURCHASE_CANCELLED = 1 
  }

  export enum DueTerms
  {
    DAYS_7 = 7,
    DAYS_14 = 14,
    DAYS_30 = 30,
    DAYS_60 = 60
  }

  export enum TaxTypes
  {
    PERCENTAGE = '1',
    FIXED = '2'
  }

  export enum DiscountTypes
  {
    PERCENTAGE = 1,
    FLAT_AMOUNT = 2
  }
  

  export class SLIDES {
    static isFinished = 1;
    static isSkipped = 2;
  }

  export enum PASSWORD_TYPE {
    PASSWORD = "password",
    TEXT = "text",
  }

  export class PRODUCT_IDENTIFIER {
    static PRO_PER_MONTH = 'pro_per_one_month'
    static PRO_PER_YEAR = 'pro_per_one_year'
  }

  export class LINKS {
    static MEMBERSHIP_PRO_LINK = "https://zace.app/#pricing"
    static APP_LINK = "https://play.google.com/store/apps/details?id=com.amazier.apps.billsreminder"
    static RATE_US_LINK = 'https://play.google.com/store/apps/details?id=com.amazier.apps.billsreminder';
    static DYNAMIC_BASE_URL ="https://envise.ascent24.io"
    static APP_ID = "com.amazier.apps.billsreminder";
    static FAQ_URL = "https://billsreminderplus.ascent24.io/#faq";
    static IOS_LINK = 'https://apps.apple.com/us/app/new-bills-reminder/id6463196849';
  }

  export class DATE_TIME_FORMAT {
    static API_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";
    static DATE_WITH_TIME = "dd-MMM-yyyy hh:mm";
    static COMMON_DATE_FORMAT = "DD MMM YYYY";
    static COMMON_DATE_FORMAT2 = "dd MMM yyyy";
    static DOWNLOAD_DATE = "dd MM yyyy";
    static API_DATE_FORMAT1 = "yyyy-MM-dd";
    static TIMEPICKER_DISPLAY_FORMAT = "hh:mm aa";
    static TIMEPICKER_API_FORMAT = "HH:mm:ssXXX";
    static DATE = "dd";
    static MONTH = "MMM";
  }

  export enum ToastTypes{
    Success = "success",
    Warning = "warning",
    Failure = "danger"
  }

  export class ROUTER_PARAM_PROPERTY {
    static readonly CLIENT_ID = "cid";
  }

  export class APP_ROUTES {
    static readonly DEFAULT_HOME = "upcoming";
    // static readonly BILLS = "bills";
    static readonly OVERDUE = "overdue";
    static readonly UPCOMING = "upcoming";
    static readonly PAID = "paid";
    static readonly UNPAID = "unpaid";

    static readonly LOGIN = "auth/login";
    static readonly ADD_BILL = 'add-bill';
    static readonly SETTINGS = 'settings';
    static readonly ABOUT = 'about';
    static readonly REPORT = 'report';
    static readonly DASHBOARD = 'dashboard';
    static readonly CLEAN_CALC = 'clean-calc';
    static readonly EMI_CALC = 'emi-calc';
    static readonly CURRENCY_CONVERTER = 'currency-converter';
    static readonly COMPOUND_INTEREST = 'compound-interest';

    static readonly FAQ = "faq";
    static readonly LIST = "list";
    static readonly FEEDBACK = "feedback";
    static readonly WHATS_NEW = "whats-new";
    static readonly FAQ_LIST = "faq/list";
    static readonly FAQ_FEEDBACK = "faq/feedback";
    static readonly FAQ_WHATS_NEW = "faq/whats-new";
    static readonly NETWORK = "network";
    static readonly AUTH = "auth";
    static readonly GUIDE = "guide";
    static readonly CALENDAR_OVERVIEW = "calendar-overview";
    static readonly DELETE = "delete";
  }
  








export enum PARAMS {
 
  ID = 'id',
  MODE = 'mode'
}

export class ITEM_CONST {
  static readonly PRICE = 0;
  static readonly QTY = 1;
  static readonly DISCOUNT = 0;
  static readonly TAX = 0;
  static readonly SHIPPING = 0;
  static readonly ITEM_NAME = 'Item';
  static readonly TAX_NAME = 'Tax';
}

export enum ChartFilter
{
  MONTHLY = 1,
  YEARLY = 2
}

export enum PAYMENT_STATUS {
  UNPAID = 1,
  PAID = 0,
  PARTIALLY_PAID = 2
}

export enum CORRECT_PAYMENT_STATUS {
  UNPAID = 0,
  PAID = 1,
  PARTIALLY_PAID = 2
}

export enum PaymentStatusLabel {
  paid = "Paid",
  unpaid = "UnPaid",
  partiallyPaid = "PartiallyPaid",
  overdue = "Overdue",
  all = "All"
}

export enum StatusFilter {
  All = "All"
}

export enum CategoryFilter {
  All = "ALL"
}

export enum FilterType 
{
  CheckBoxType = "Check-Box Type" ,
  MultiDropdownType = "Multi-Dropdown Type" ,
  SingleDropdownType = "Single-Dropdown Type" ,
  SegmentType = "Segment Type"
}

export enum Operators {
  PERIOD = '.',
  MULTIPLY = '*',
  SUBTRACT = '-',
  ADD = '+',
  DIVIDE = '/',
  CLEAR = 'C',
  EQUAL = '=',
}

export interface CategoryListObj
{
  categoryid: number,
  categoryname: string
}

export interface BackupPaidBillsObj
{
  billId: number,
  paidDate: string,
  dueDate: string,
  isCreated: boolean,
  id: number,
  amount: number,
}

export enum PaymentId
{
  Payable = 2,
  Receivable = 1
}
export interface TypeListObj
{
  id: PaymentId,
  name: string
}

export enum ReminderId
{
  OnDueDate = 0,
  OneDay = 1,
  TwoDays = 2,
  ThreeDays = 3,
  FourDays = 4,
  FiveDays = 5,
  SixDays = 6,
  SevenDays = 7,
  EightDays = 8,
  NineDays = 9,
  TenDays = 10,
  ElevenDays = 11,
  TwelveDays = 12,
  ThirteenDays = 13,
  FourteenDays = 14,
  FifteenDays = 15
}

export interface ReminderLsObj
{
  id: ReminderId,
  name: string
}

export enum RepeatFilterId
{
  Day = 'days',
  Week = 2,
  Month = 'months',
  Year = 'years'
}

export enum CategoryListId
{
  Other = 24,
  Add_New = 25
}

export interface RepeatFilterObj
{
  id: RepeatFilterId,
  name: string,
  interval: RepeatInterval,
}

export interface CurrencyObj
{
  code: string,
  name: string,
  symbol: string
}

export enum UpcomingBillsId
{
  Next_30 = 1,
  All = 2
}
export interface UpcomingBillsObj
{
  id: UpcomingBillsId,
  name: string
}

// export enum LanguageId
// {
//   English = 1,
//   Coming_Soon = 2
// }

// export interface LanguageObj
// {
//   id: LanguageId,
//   name: string
// }

export enum BillType
{
  UPCOMING = 'upcoming',
  OVERDUE = 'overdue',
  UNPAID = 'unpaid',
  PAID = 'paid',
  TODAY = 'today',
  PAYABLE = 'payable',
  RECEIVABLE = 'receivable',
}

export enum BillStatusType
{
  UPCOMING_PAYABLE = 'upcoming_payable',
  OVERDUE_PAYABLE = 'overdue_payable',
  UNPAID_PAYABLE = 'unpaid_payable',
  PAID_PAYABLE = 'paid_payable',
  TODAY_PAYABLE = 'today_payable',
  UPCOMING_RECEIVABLE = 'upcoming_receivable',
  OVERDUE_RECEIVABLE = 'overdue_receivable',
  UNPAID_RECEIVABLE = 'unpaid_receivable',
  PAID_RECEIVABLE = 'paid_receivable',
  TODAY_RECEIVABLE = 'today_receivable',
}

export enum ChartFilterId
{
  STATUS = 1,
  CATEGORY = 2,
  TYPE = 3,
}

export enum RepeatCode
{
  ONE_TIME = "0",
  EVERY_DAY = "1",
  WEEKLY = "7",
  BI_WEEKLY = "14",
  TRI_WEEKLY = "21",
  EVERY_MONTH = "30",
  EVERY_YEAR = "365",
  CUSTOM = "-1",
}

export enum RepeatInterval
{
  ONE_TIME = 0,
  EVERY_DAY = 1,
  WEEKLY = 7,
  BI_WEEKLY = 14,
  TRI_WEEKLY = 21,
  EVERY_MONTH = 1,
  EVERY_YEAR = 1,
  CUSTOM = -1,
}

export enum RepeatId
{
  ONE_TIME = 1,
  EVERY_DAY = 2,
  WEEKLY = 3,
  BI_WEEKLY = 4,
  TRI_WEEKLY = 5,
  EVERY_MONTH = 6,
  EVERY_YEAR = 7,
  CUSTOM = 8,
}

export enum RepeatIntervalName
{
  NO_REPEAT = "norepeat",
  DAYS = "days",
  MONTHS = "months",
  YEARS = "years",
  CUSTOM = "custom",
}

export enum DatabaseAppID
{
  BILLS_REMINDER_PLUS = "1",
  BILLS_REMINDER = "2"
}
export interface RepeatListObject
{
  code: RepeatCode,
  type: string,
  intervalName: RepeatIntervalName,
  interval: RepeatInterval,
  id: RepeatId,
}
export interface ChartFilterObj
{
  id: ChartFilterId,
  name: string
}
export interface FilterObj
{
  name : string ,
  type : FilterType ,
  fieldName : string ,
  filteringLs :
    {
      name : string ,
      id : string | boolean ,
      checked : boolean
    }[]
}

export interface BackupBillObj
{
  id: string,
  bill: string,
  amount: number,
  payURL: string,
  categoryName: string,
  due_date: string,
  due_hours: number,
  due_mins: number,
  type: number,
  category: number,
  status: number,
  paid_date: string,
  remaind_before: number,
  repeat: number,
  repeatEveryNO: number,
  repeatEveryMonth: number,
  repeatUpto: string,
  parentKey: number,
  currency: string,
  notes: string,
}

export enum FirebaseErrorMessage {
  EMAIL_ALREADY_USED = 'auth/email-already-in-use'
}

export enum Provider {
  APPLE = 'apple.com'
}

export class AppleSignIn {
  static readonly CLIENT_ID = 'com.amazier.apps.billsreminder';
  static readonly REDIRECT_URI = 'https://billsreminder-auth.ascent24.io/__/auth/handler';
  static readonly SCOPES = 'email';
  static readonly STATE = '12345';
}

export enum EDIT_BILL_OPTIONS {
  THIS_BILL_ONLY = 'THIS_BILL_ONLY',
  FUTURE_BILL_ONLY = 'FUTURE_BILL_ONLY',
  CANCEL = 'CANCEL',
}
export enum HOST
{
  APPLE_DUMMY = 'privaterelay.appleid.com'
}

export enum ZACE
{
  BASE_URL = 'https://api.zace.app/'
}