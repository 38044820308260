import { Location } from "@angular/common";
import { Component, NgZone } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { Router } from "@angular/router";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { ActionSheetController, LoadingController, MenuController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Subscription, lastValueFrom } from 'rxjs';
import { UtilFunctions } from 'src/app/util/util';

import { LoggerService } from './services/logger/logger.service';
import { getIsGuestUser, selectLoginResponse } from './store/auth/auth.selectors';
import firebase from 'firebase/compat/app';
import { HttpErrorResponse } from "@angular/common/http";
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { Apollo, gql } from "apollo-angular";
import { User } from "firebase/auth";
import { SettingsService } from "./services/settings/settings.service";
import { BASE_CONFIG } from './util/base-settings';
import { APP_ROUTES, COMPONENT_NAME, DATE_TIME_FORMAT, LINKS, PLATFORM } from './util/constants';
import { DateFunctions } from "./util/date-functions";

import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";
import { DatabaseAppID } from './util/constants';
import { GoogleRedirectionStorageAction, TokenStorageAction } from "./store/auth/auth.actions";
import { UserAuthenticationService } from "./shared/service/user-authentication.service";
import { AngularFireAuth } from "@angular/fire/compat/auth";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isGuestUser: boolean = true;
  subscriptions: Subscription[] = [];
  userDetails: User;
  isWeb: boolean = false;
  constructor(
    private logger: LoggerService,
    private store: Store,
    private platform: Platform,
    private dialog: ModalController,
    private loader : LoadingController ,
    private popoverController: PopoverController,
    private actionSheetController: ActionSheetController,
    private userAuthenticationService: UserAuthenticationService,
    private util: UtilFunctions,
    private _location : Location,
    private router : Router,
    private zone:NgZone,
    private menuController : MenuController,
    private apollo: Apollo,
    private dateFunctions: DateFunctions,
    private settingsService: SettingsService,
    private androidPermissions: AndroidPermissions,
    public afAuth: AngularFireAuth,
    // private geolocation: Geolocation, private nativeGeocoder: NativeGeocoder
  ) {
      this.initializeApp();
      // this.getDatabaseData();
  }

  async initializeApp() {

  try {
    if (BASE_CONFIG.IS_DEBUG) console.log("initializeApp",BASE_CONFIG.VERSION);
    this.processDeeplink();
    BASE_CONFIG.PLATFORM = Capacitor.getPlatform();
    var deviceInfo = await Device.getInfo();
    BASE_CONFIG.DEVICE = deviceInfo;

    this.platform.ready().then(async () => {
    
      if (BASE_CONFIG.PLATFORM == PLATFORM.web) {
        //  GoogleAuth.initialize();
         // use hook after platform dom ready
         document.body.classList.add('web');
         GoogleAuth.initialize({
          clientId:
            '1026523308150-96on3ad4pksgb10m3rsnc0jrgja2h0u0.apps.googleusercontent.com',
          scopes: ['profile', 'email'],
          grantOfflineAccess: true,
        });
        BASE_CONFIG.IS_WEB = true;
        if(BASE_CONFIG.IS_WEB) {
          this.checkItIsFromGoogleRedirect();
        }
      } else {
        document.body.classList.add('app');
     
        let data = require('./../locale/messages.' +
          BASE_CONFIG.LANGUAGE +
          '.json').translations;
          document.body.className=BASE_CONFIG.LANGUAGE;
        // load translations at runtime
        loadTranslations(data);
        require("../assets/app.scss");
        BASE_CONFIG.IS_WEB = false;

            this.configPushNotification();

        this.askPermissions();
      }
      this.setupBackButtonListener();
    });
    this.checkGuestUser();
    // this.store.select(getUserDetails()).subscribe((response: any) => {
    //   if (response) {
    //     this.userDetails = response;
    //   }
    // });
    // if (!BASE_CONFIG.IS_WEB) {
    //   let deviceid = null;
    //   deviceid = await FirebaseMessaging.getToken().then().catch( err => {

    //   });
    //   this.updateDevice(deviceid);

    //   // this.apiGetAllDeviceId(deviceid);
    // }
    // this.getSettings();

    } catch (error) {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        'error in initializeApp',
        error.toString(),
        'initializeApp'
      );
      this.logger.log(logRequest);
    } 
  }

  async checkItIsFromGoogleRedirect()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log('checkItIsFromGoogleRedirect');
      let load = await this.loader.create({
        message: 'Please wait...', id: 'redirect_login_loader', cssClass: 'loader_wait',
      });

      await load.present();
      let result = await this.afAuth.getRedirectResult();
      if (result && result.user && result.credential)
      {
        const user = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName,
          photoURL: result.user.photoURL ? result.user.photoURL : result.additionalUserInfo.profile['picture'] ? result.additionalUserInfo.profile['picture'] : null,
          emailVerified: result.user.emailVerified,
        };
        this.userAuthenticationService.setUserData(user);

        firebase.auth().onIdTokenChanged(user =>
        {
          user.getIdToken().then(async token =>
          {
            await lastValueFrom(this.store.dispatch(new TokenStorageAction(token)));
            await lastValueFrom(this.store.dispatch(new GoogleRedirectionStorageAction(false)));
            this.loader.dismiss(undefined, undefined, 'redirect_login_loader');
            this.util.navigate(APP_ROUTES.OVERDUE);
          });
        });
      }
      else
      {
        if (this.router.url.split("?")[0] == '/' + APP_ROUTES.DELETE)
        {
          this.loader.dismiss(undefined, undefined, 'redirect_login_loader');
          return;
        }
        this.store.dispatch(new GoogleRedirectionStorageAction(false));
        this.loader.dismiss(undefined, undefined, 'redirect_login_loader');
        this.util.navigate(APP_ROUTES.LOGIN);
      }
    } catch (error)
    {
      this.store.dispatch(new GoogleRedirectionStorageAction(false));
      this.util.logFunc('checkItIsFromGoogleRedirect', error, COMPONENT_NAME.APP_COMPONENT);
      this.loader.dismiss(undefined, undefined, 'redirect_login_loader');
      this.util.navigate(APP_ROUTES.LOGIN);
    }
  }

//   async getDatabaseData()
//   {
//     try {
//       if (BASE_CONFIG.IS_DEBUG) console.log('getDatabaseData');
//         const existingDatabase = await this.openExistingDatabase();
//         this.file.moveFile("/storage/emulated/0/billsremindertwo/billManager", "billManager", "/data/data/com.amazier.apps.billsreminder/databases", "billManagerSQLite.db");
//         const query = 'SELECT * FROM bills';
//         await CapacitorSQLite.open({ database: 'billManager', readonly: false });
//         const result = await CapacitorSQLite.query({ database: 'billManager', statement: query, values: []});
//         const jsonData = JSON.stringify(result.values);
//         console.log(jsonData);
//         window.alert(jsonData);
//         await CapacitorSQLite.close({ database: 'billManager', readonly: false });
//         // await CapacitorSQLite.closeNCConnection({ databasePath: '/storage/emulated/0/billsremindertwo/billManager', version: 2 });
//         await CapacitorSQLite.closeConnection({ database: 'billManager', version: 2, encrypted: true });
//     } catch (error) {
//       window.alert(error);
//       await CapacitorSQLite.closeConnection({ database: 'billManager', version: 2, encrypted: true });
//       this.util.logFunc('getDatabaseData', error, COMPONENT_NAME.APP_COMPONENT);
//     }
//   }

//   async openExistingDatabase()
//   {
//     try {
//       if (BASE_CONFIG.IS_DEBUG) console.log('openExistingDatabase');
//       // const db = await CapacitorSQLite.createNCConnection({ databasePath: '/storage/emulated/0/billsremindertwo/billManager', version: 2 });
//       await CapacitorSQLite.createConnection({ database: 'billManager', version: 2, encrypted: true });
//       const db = await CapacitorSQLite.getDatabaseList();
//       console.error(db);
//       return db;
//     } catch (error) {
//       window.alert(error);
//       this.util.logFunc('openExistingDatabase', error, COMPONENT_NAME.APP_COMPONENT);
//     }
//   }

async askPermissions()
{
  try
  {
    if (BASE_CONFIG.IS_DEBUG) console.log('askPermissions');

    await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
      async result =>
      {
        if (!result.hasPermission)
        {
          await this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
        }
      },
      async err =>
      {
        await this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
      }
    );
  } catch (error)
  {
    this.util.logFunc('askPermissions', error, COMPONENT_NAME.APP_COMPONENT);
  }
}

  processDeeplink()
  {
    try
    {
      if(BASE_CONFIG.IS_DEBUG)  console.log('processDeeplink');
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) =>
      {
        this.zone.run(() =>
        {
          if (!event || !event.url) { return; }

          // getting project id from url
          const domain = LINKS.DYNAMIC_BASE_URL + '?aid=';

          const invoiceId = event.url.split(domain).pop();

          if (!invoiceId) { return; }

        this.store.selectOnce(selectLoginResponse())
            .subscribe((details:any) =>
            {
              if (details&&details.loginDetails )
              {
                const paramObj = {
                  aId: invoiceId,
               
                };

                // this.util.navigate(APP_ROUTES.DEFAULT_HOME, paramObj);
              }
              else 
              {
              }
            });
       
        });
      });
    }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        'error in processDeeplink',
        error.toString(),
        'processDeeplink'
      );
      this.logger.log(logRequest);
    }
  }
  configPushNotification(){
    if(!BASE_CONFIG.IS_WEB){
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });
  
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {
          if(BASE_CONFIG.IS_DEBUG) console.log('Push registration success, token: ' + token.value);
        }
      );
  
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          if(BASE_CONFIG.IS_DEBUG) console.error('Error on registration: ' + JSON.stringify(error));
        }
      );
  
      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          if(BASE_CONFIG.IS_DEBUG) console.log('Push received: ' + JSON.stringify(notification));
        }
      );
  
      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          if(BASE_CONFIG.IS_DEBUG) console.log('Push action performed: ' , notification);
          // if(notification.notification){
          //   this.store.selectOnce(selectToken())
          //   .subscribe((details: string) =>
          //   {
          //     if (details)
          //     {
          //       this.util.navigate(APP_ROUTES.DEFAULT_HOME, null, true);
          //       // this.util.navigate(,{});
          //     }
          //     else 
          //     {
          //     }
          //   });
          // }
          
        }
      );
      }
  }
  setupBackButtonListener() {
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log('setupBackButtonListener');

      let that = this ;
      App.addListener('backButton', async (data) => {
        let isModalExist = await that.dialog.getTop();
        let isActionSheet = await that.actionSheetController.getTop();
        let isPopover = await that.popoverController.getTop();
        let isMenuOpen = await that.menuController.isOpen();
        if (data.canGoBack || isModalExist || isActionSheet || isPopover || isMenuOpen) {
          this.backButtonGoBack();
        }else{
          App.exitApp();
        }
      });
    } catch (error) {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        'error in setupBackButtonListener',
        error.toString(),
        'setupBackButtonListener'
      );
      this.logger.log(logRequest);
    }
  }

  async backButtonGoBack()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("backButtonGoBack");
      let isModalExist = await this.dialog.getTop();
      let isActionSheet = await this.actionSheetController.getTop();
      let isPopover = await this.popoverController.getTop();
      let isMenuOpen = await this.menuController.isOpen();

      if (!isModalExist && !isActionSheet && !isPopover && !isMenuOpen && !BASE_CONFIG.IS_SCANNER_OPEN)
      {
        // console.error(this.router.url);
        if (this.router.url == '/upcoming')
        {
          App.exitApp();
        } else
        {
          this._location.back();
        }
      }
      else {
        return ;
      }
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        "error in backButtonGoBack",
        err.toString(),
        "backButtonGoBack",
        // LOG_TYPE.ERROR
      );
      this.logger.log(body);
    }
  }


  checkGuestUser()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("checkGuestUser");

      let sGU = this.store
      .select(getIsGuestUser())
      .subscribe((isGuestUser: boolean) => {
          BASE_CONFIG.IS_GUEST_USER=isGuestUser;
      
      });
      sGU.unsubscribe();

    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        COMPONENT_NAME.APP_COMPONENT,
        "error in checkGuestUser",
        err.toString(),
        "checkGuestUser",
      );
      this.logger.log(logRequest);
    }
  }

  updateDevice(pDeviceid) {
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("updateDevice");
      if (this.userDetails) {
        let req = {
          userid: this.userDetails.uid,
          devicetoken: pDeviceid,
          devicetype: BASE_CONFIG.PLATFORM,
          app: DatabaseAppID.BILLS_REMINDER
        }
        let retValue$ = this.settingsService.deviceUpdate(req);
        retValue$.subscribe({
          next: (res) => {

          },
          error: (err: HttpErrorResponse) => {


          },

        })
      }
    } catch (error) {
      this.util.logFunc('updateDevice', error, COMPONENT_NAME.APP_COMPONENT);
    }
  }

  private getSettings() {
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("getSettings");
      const settingsQuery = `query getSettings { 
        billsreminder_app_setting { 
           currency,
           remind_time,
           remind_before,    
           language,
           notification_type,
           setting_id,
           email_notification,
           app_notification
           currencyByCurrency {
           name
           symbol
        }
       }
       
      }`;
      this.apollo
        .subscribe({
          query: gql`
        ${settingsQuery}
        `,
          errorPolicy: 'all'
        })
        .subscribe((result: any) => {
          if (result && result.data) {
            if (result.data.billsreminder_app_setting.length == 0) {
              this.updateSettings();

            } else {
              BASE_CONFIG.CURRENCY_CODE = result.data.billsreminder_app_setting[0].currency;
              BASE_CONFIG.CURRENCY_SYMBOL = result.data.billsreminder_app_setting[0].currencyByCurrency.symbol;
            }
          } else if (result && result.errors) {
            this.util.handleErrorStatus(COMPONENT_NAME.SETTINGS_PAGE, "getBills", result.errors[0].message.toString());
            this.util.errorHandling(result.errors);
          }
        });
    } catch (error) {
      this.util.logFunc('getSettings', error, COMPONENT_NAME.APP_COMPONENT);
    }
  }

  updateSettings() {
    let retValue;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("updateSettings");
      this.apollo
        .mutate({
          mutation: gql`
      mutation UpdateSetting{
        insert_billsreminder_app_setting (
          objects: [{
          currency: "${BASE_CONFIG.CURRENCY_CODE}", 
          email_notification: ${BASE_CONFIG.emailNotification},
          notification_type: ${BASE_CONFIG.notificationType},
          app_notification:   ${BASE_CONFIG.appNotification},
          language:  "${BASE_CONFIG.LANGUAGE}", 
          remind_before: "${BASE_CONFIG.remindBefore}",
          remind_time: "${this.dateFunctions.formatDate(new Date(), DATE_TIME_FORMAT.TIMEPICKER_DISPLAY_FORMAT)}",     
                userid : "${this.userDetails.uid}",
    }],
          on_conflict: {
            constraint: app_setting_userid_key
            update_columns: [currency, email_notification, notification_type, app_notification, language, remind_before, remind_time, userid ]
          }
        ) {
          returning {
            setting_id
            currency
            remind_time
            remind_before    
            language
            notification_type                      
            email_notification
            app_notification
            currencyByCurrency {
            name
            symbol
         }
          }
        }
      }
      `,
          errorPolicy: 'all'
        })
        .subscribe((result: any) => {
          // if (result &&result.data&& result.data.insert_billsreminder_app_setting) {
          //   this.util.toShowToast($localize`:@@addBill-billSuccMsg:Changes saved`, ToastTypes.Success);
          // } else if (result && result.errors) {
          //   this.util.handleErrorStatus(COMPONENT_NAME.SETTINGS_PAGE, "updateSettings", result.errors[0].message.toString());
          //   this.util.errorHandling(result.errors);
          // }
        });
    } catch (error) {
      this.util.logFunc('updateSettings', error, COMPONENT_NAME.APP_COMPONENT);
    }
    return retValue;
  }


}
