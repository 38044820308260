export enum BillActionTypes
{
    BillDetail = '[Bill Page] Bill Detail',
    BackupBill = '[Backup Bill] Backup Bill',
    BackupBillsLs = '[Backup BillsLs] Backup BillsLs',
    BackupAttempt = '[Backup Attempt] Backup Attempt',
    BackupPaidBillsLs = '[Backup PaidBillsLs] Backup PaidBillsLs',
    BackupProcess = '[Backup Process] Backup Process',
}

export class BillDetail
{
    static readonly type = BillActionTypes.BillDetail;
    constructor(public billDetail: any) { }
}

export class BackupBill
{
    static readonly type = BillActionTypes.BackupBill;
    constructor(public backupBill: any) { }
}

export class BackupBillsLs
{
    static readonly type = BillActionTypes.BackupBillsLs;
    constructor(public backupBillsLs: any) { }
}

export class BackupAttempt
{
    static readonly type = BillActionTypes.BackupAttempt;
    constructor(public backupAttempt: any) { }
}

export class BackupPaidBillsLs
{
    static readonly type = BillActionTypes.BackupPaidBillsLs;
    constructor(public backupPaidBillsLs: any) { }
}

export class BackupProcess
{
    static readonly type = BillActionTypes.BackupProcess;
    constructor(public backupProcess: any) { }
}