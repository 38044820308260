import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_ROUTES } from './util/constants';

const routes: Routes = [
 
  {
    path: APP_ROUTES.AUTH,
    children : [
      {
        path : '',
        loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule)
      }
    ]
  },
  {
    path: APP_ROUTES.NETWORK,
    children:[
      {
        path:'',
        loadChildren: () => import('./network/network.module').then( m => m.NetworkPageModule)
      }
    ]
  },
  {
    path: APP_ROUTES.GUIDE,
    children:[
      {
        path:'',
        loadChildren: () => import('./guide/guide.module').then( m => m.GuidePageModule)
      }
    ]
  },
  {
    path: APP_ROUTES.DELETE,
    loadChildren: () => import('./delete/delete.module').then(m => m.DeletePageModule)
  },
  {
  
    path : '' ,
    loadChildren : () => import('./home/home.module').then(m => m.HomePageModule)  
 
  },

  

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { 
      // preloadingStrategy: PreloadAllModules ,
      useHash : false,
      // onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
