import { AuthModel } from './../../model/auth.model';
import { LoggerModel } from './../../model/logger.model';
import { createSelector } from "@ngxs/store";
import { AuthState, LogReqState } from "./auth.state";

export const  selectToken = () =>
 createSelector([ AuthState ] , (state : AuthModel) => state.token ) ;

export const selectLoginResponse = () =>
createSelector([ AuthState], (state : AuthModel) => state.loginResponse);

export const getLogReq = () =>
createSelector([LogReqState], (state: LoggerModel) => state.logRequest);

export const getUserPlan = () => 
createSelector([AuthState], (state: AuthModel) => state.userPlan);

export const getIsIntroslidesVisited = () => 
createSelector([AuthState], (state: AuthModel) => state?state.isIntroSlidesVisited: false);

export const getUserDetails = () => 
createSelector([AuthState], (state: AuthModel) => state.userDetails);

export const getIsGuestUser = () => 
createSelector([AuthState], (state : AuthModel) => state.isGuestUser);

export const selectGoogleRedirection = () =>
  createSelector([AuthState], (state: AuthModel) => state.isRedirectedToGoogle);

export const selectLoginPageCondt = () =>
  createSelector([AuthState], (state : AuthModel) => [{isRedirectedToGoogle : state.isRedirectedToGoogle , token : state.token}])