import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, NgxsModuleOptions } from '@ngxs/store';
import { BillDetailState } from 'src/app/store/bill/bill.state';

import { environment } from '../../../environments/environment';
import { AuthState, LogReqState } from './../../store/auth/auth.state';
import { SettingsState } from './../../store/settings/settings.state';
import { DateState } from 'src/app/store/date/date.state';


const ngxsMainStates =
  [
    AuthState,
    LogReqState,
    SettingsState,
    BillDetailState,
    DateState
  ];
const ngxsStorageStates =
  [
    AuthState,
    LogReqState,
    SettingsState,
    BillDetailState,
    DateState
  ];

const ngxsConfig: NgxsModuleOptions = {
  developmentMode: !environment.production,
  selectorOptions: {
    suppressErrors: false,
    injectContainerState: false,
  },
  compatibility: {
    strictContentSecurityPolicy: true,
  },
};

export const ngxsImports = [
  NgxsModule.forRoot(ngxsMainStates, ngxsConfig),
  NgxsStoragePluginModule.forRoot({
    key: ngxsStorageStates,
  }),  //In order to persist all states
  NgxsReduxDevtoolsPluginModule.forRoot({
    disabled: environment.production
  }),   //allow us to debug the store in real-time from the browser

];
