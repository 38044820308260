import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { DateModel } from "src/app/model/date.model";
import { OverdueDateAction, PaidDateAction, UnpaidDateAction, UpcomingDateAction } from "./date.actions";


export const dateInitState: DateModel = {
  upcoming : null,
  overdue: null,
  unpaid: null,
  paid: null,
}

export const DATE_TOKEN = new StateToken<DateModel>('date');

@State({
  name: DATE_TOKEN,
  defaults: dateInitState,
})

@Injectable()
export class DateState {
  constructor() {}

  @Action(UpcomingDateAction)
  getUpcomingDate(ctx: StateContext<DateModel>, action:UpcomingDateAction ) {
    
      ctx.patchState({
        upcoming: action.upcoming
      })
  }

  @Action(OverdueDateAction)
  getOverdueDate(ctx: StateContext<DateModel>, action:OverdueDateAction ) {
    
      ctx.patchState({
        overdue: action.overdue
      })
  }

  @Action(UnpaidDateAction)
  getUnpaidDate(ctx: StateContext<DateModel>, action:UnpaidDateAction ) {
    
      ctx.patchState({
        unpaid: action.unpaid
      })
  }

  @Action(PaidDateAction)
  getPaidDate(ctx: StateContext<DateModel>, action:PaidDateAction ) {
    
      ctx.patchState({
        paid: action.paid
      })
  }
}