import { COMPONENT_NAME } from './../../util/constants';
import { Component, Input, OnInit } from '@angular/core';
import { AlertModalStatus, AlertModalData } from 'src/app/model/alert-modal.model';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { BASE_CONFIG } from 'src/app/util/base-settings';

@Component({
  selector: 'app-custom-modal-component',
  templateUrl: './custom-modal-component.component.html',
  styleUrls: ['./custom-modal-component.component.scss'],
})
export class CustomModalComponent implements OnInit {

  
  readonly modalStatus =AlertModalStatus;
  @Input() public data: AlertModalData;
  @Input() isJwtModal:boolean=false;
  @Input() isInnerHtml: boolean = false;
  @Input() isPayment:boolean=false;
  @Input() isReset:boolean=false;

  emitFunc:any;
  isString: boolean;
  constructor(


    private logger: LoggerService,
  ) {}

   async onClose(isConfirm?:boolean): Promise<void> {
     try{
      if(BASE_CONFIG.IS_DEBUG) console.log("onClose");
  
       this.emitFunc(isConfirm,this.data.status);
  
     }
     
     catch (err) {
      let logRequest = this.logger.buildRequest(
       COMPONENT_NAME.CUSTOM_MODAL_COMPONENT,
     "error in onClose",
     err.toString(),
      "onClose",
       );
      this.logger.log(logRequest);
     }
   }
  ngOnInit(){
    this.isString=typeof this.data.desc=='string';
  }

 

}
