import { SettingsModel } from './../../model/settings.model';
import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { GeoLocationAction, IsForceUpdateAlertShownAction, LanguageAction } from './settings.actions';


export const settingsInitState: SettingsModel = {
  activeLang: null,
  location: null,
  isUpdateShown: false
}

export const SETTINGS_TOKEN = new StateToken<SettingsModel>('settings');

@State({
  name: SETTINGS_TOKEN,
  defaults: settingsInitState,
})

@Injectable()
export class SettingsState {
  constructor() {}

  @Action(LanguageAction)
  updateActiveLang(ctx: StateContext<SettingsModel>, action:LanguageAction ) {
    
      ctx.patchState({
        activeLang: action.language 
      })
  }

  @Action(GeoLocationAction)
  getDeviceLocation(ctx: StateContext<SettingsModel>, action:GeoLocationAction ) {
    
      ctx.patchState({
        location: action.location 
      })
  }

  @Action(IsForceUpdateAlertShownAction)
  getUserSettings(ctx: StateContext<SettingsModel>, action:IsForceUpdateAlertShownAction ) {
    
    ctx.patchState({
      isUpdateShown: action.isShown 
    })
}
}