import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { lastValueFrom } from 'rxjs';

import { FILE_NAME } from 'src/app/util/constants';
import { LoggerService } from "../../services/logger/logger.service";
import { GuestUserAction, LoginResponseAction, LogReqAction, TokenStorageAction, UserDetailAction } from './../../store/auth/auth.actions';

@Injectable({
  providedIn: "root"
})

export class ClearStoreFunction
{
  constructor(
    private store: Store, 
    private logger: LoggerService )
  {

  }

  async toClearStore()
  {
    try
    {
      await lastValueFrom( this.store.dispatch(
        [
          new TokenStorageAction(null) ,
          new LoginResponseAction(null),
          new LogReqAction(null),
          new UserDetailAction(null),
         
          new GuestUserAction(null)
        ]));
    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.CLEAR_STORE,
        "error in clearStoreFunction",
        error.toString(),
        "toClearStore"
      );
      this.logger.log(logRequest);
    }
  }

 

 
}