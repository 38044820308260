

export class LanguageAction {
  static readonly type = '[settings] lang state';
  constructor(public language: string) {
   
  }
}


export class GeoLocationAction {
  static readonly type = '[settings] location state';
  constructor(public location: any) {
   
  }
}

export class IsForceUpdateAlertShownAction {
  static readonly type = '[settings] update alert state';
  constructor(public isShown: any) {
   
  }
}


