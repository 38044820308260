import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { JwtInterceptor } from './services/http/jwt.interceptors';
import { SharedModule } from './shared/shared.module';
// import { NgSelectModule } from "@ng-select/ng-select"; 
import { ArcElement, BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, LineController, LineElement, PieController, PointElement, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GraphQLModule } from './graphql.module';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
// import { IonicSelectableModule } from '@ionic-selectable/angular';



// What you register will depend on what chart you are using and features used.
Chart.register(BarController, BarElement, CategoryScale, LinearScale,PieController,ArcElement, LineController, LineElement, PointElement, Title, Tooltip, Legend,ChartDataLabels);
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    CoreModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence(),
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    // NgSelectModule,
    HttpClientModule,
    GraphQLModule,
    // IonicSelectableModule.forRoot()
    NgxDaterangepickerMd.forRoot(),
  ],
  providers: [AndroidPermissions,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // {
    //   provide: APOLLO_OPTIONS,
    //   useFactory(httpLink: HttpLink) {
    //     return {
    //       cache: new InMemoryCache(),
    //       link: httpLink.create({
    //         uri: 'https://appgql.api.ascent24.io/v1/graphql',
    //         withCredentials: true,

    //       }),
    //     };
    //   },
    //   deps: [HttpLink],
    // },
      ],
  bootstrap: [AppComponent],
})
export class AppModule {}
