export enum LOG_TYPE {
  INFO = "info",
  ERROR = "error",
  CRITICAL = "critical",
  WARNING = "warning",
  VERY_CRITICAL = "veryCritical"
}

export interface LoggerRequest {
  errtitle: string;
  functionname: string;
  level: LOG_TYPE;
  message: string;
  module: string;
  pathName: string;
  appId: string;
  browser: string;
  browserversion: string;
  ostype: string;
  timestamp: string;
  versioncode: string;
  [key:string]: any;
}

export interface LoggerModel {
  logRequest : LoggerRequest
} 
