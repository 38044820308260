import { environment } from '../../environments/environment';


/* It's a class that contains static variables that are used throughout the app. */
export class BASE_CONFIG {
    static readonly IS_DEBUG=!environment.production;
    static readonly VERSION=environment.version;
    static readonly LOGGER_URL = environment.loggerUrl;
    static readonly LOG_APP_ID=environment.logAppId
    static readonly orgId=environment.orgId;
    static CURRENCY_CODE = 'USD' ;
    static CURRENCY_SYMBOL = '$' ;
    static readonly remindBefore = 0;
    static readonly emailNotification = true;
    static readonly notificationType = true;
    static readonly appNotification = true;
    static DEVICE_LATITUDE=null
    static DEVICE_LONGITUDE=null
    static DEVICE_ID = null
    static DEVICE = null
    static NETWORK_STATUS = false;
    static PLATFORM = '';
    static LANGUAGE = 'en'
    static isDevice = false
    static IS_WEB = false;
    static IS_GUEST_USER =false;
    static IS_SCANNER_OPEN = false;
    }
