import { User } from './../../model/user.model';
import { LoggerRequest } from './../../model/logger.model';

export class TokenStorageAction 
{
  static readonly type = '[token] set token';
  constructor(public token : string) {}

}

export class LoginResponseAction
{
  static readonly type = '[LoginResponse] set loginResponse' ;
  constructor( public loginResponse : any) {}

}


export class LogReqAction {
  static readonly type = '[logReq] state';
  constructor(public logReq: LoggerRequest) {
   
  }
}




export class IntroVisitAction {
  static readonly type = '[introslides] state';
  constructor(public introslides: boolean) {
   
  }
}

export class UserDetailAction {
  static readonly type = '[userdetail] state';
  constructor(public user: User) {
   
  }
}
export class GuestUserAction
{
  static readonly type = '[guestuser] state';
  constructor(public isGuestUser: boolean) { }
}

export class GoogleRedirectionStorageAction
{
  static readonly type = '[GoogleRedirection] store GoogleRedirection';
  constructor(public googleRedirection: any) { }
}