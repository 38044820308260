

export class UpcomingDateAction {
  static readonly type = '[date] upcoming state';
  constructor(public upcoming: string[]) {
   
  }
}


export class OverdueDateAction {
  static readonly type = '[date] overdue state';
  constructor(public overdue: string[]) {
   
  }
}

export class UnpaidDateAction {
  static readonly type = '[date] unpaid state';
  constructor(public unpaid: string[]) {
   
  }
}

export class PaidDateAction {
  static readonly type = '[date] paid state';
  constructor(public paid: string[]) {
   
  }
}
