import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, ApolloLink, InMemoryCache, split} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { HttpClientModule } from '@angular/common/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

const uri = 'https://appgql.api.ascent24.io/v1/graphql'; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }));
 
  const auth = setContext((operation, context) => {
    const sToken = localStorage.getItem('auth');
 let token=JSON.parse(sToken);

    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token['token']}`,
        },
      };
    }
  });
 
  const http = httpLink.create({ uri });
  const ws = new WebSocketLink({
    uri: `wss://appgql.api.ascent24.io/v1/graphql` ,
    options: {
      reconnect: true,
    }
  });
  const subLink = split(
    ({query}) => {
      const data = getMainDefinition(query);
      return (
        data.kind === 'OperationDefinition' && data.operation === 'subscription'
      );
    },
    ws,
    http
  );
  const link = ApolloLink.from([basic, auth, subLink]);
  const cache = new InMemoryCache();
  return {
    link,
    cache,
  };
}
 
@NgModule({
  exports: [HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}