import { AuthResponseModal } from './../../model/auth.model';
import { BASE_CONFIG } from './../../util/base-settings';

import { Injectable } from '@angular/core';
import { FILE_NAME, SERVICE_URL } from 'src/app/util/constants';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { Observable } from 'rxjs';
import { APIResponse } from 'src/app/model/Response.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private logger:LoggerService,private HttpPost:HttpService) { }

  apiGetAuthReq (pToken: string) {
    let retValue: any;
  try {
    if (BASE_CONFIG.IS_DEBUG) console.log("apiGetAuthReq");
    const hVal= "Bearer " + pToken;
   
    retValue={
        "token": hVal,
        "orgId":BASE_CONFIG.orgId
    }

  } catch (err : any) {
    let logRequest = this.logger.buildRequest(
      FILE_NAME.AUTH_SERVICE,
      "error in apiGetAuthReq",
      err.toString(),
      "apiGetAuthReq",
    );
    this.logger.log(logRequest);
        }
    return retValue;
  };

  async apiGetAuthRes(pToken: any): Promise<AuthResponseModal> {
    let retValue: Promise<AuthResponseModal>;

    try {
      if (pToken) {
        retValue = await this.HttpPost.makeHttpGetRequest(SERVICE_URL.APP_AUTH_URL,null,false,pToken);
      } else {
        throw "invalid request";
      }
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
      "error in apiGetAuthRes",
      err.toString(),
       "apiGetAuthRes",
        );
       this.logger.log(logRequest);
    }
    return retValue;
  }

  sendDeleteEmail(req: any): Observable<APIResponse<any>>
  {
    let retValue: Observable<APIResponse<any>>;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log('sendDeleteEmail');
      retValue = this.HttpPost.makeHttpPostRequest(SERVICE_URL.API_SEND_EMAIL, req);
    } catch (error)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        'error in sendDeleteEmail',
        error.toString(),
        'sendDeleteEmail'
      );
      this.logger.log(logRequest);
    }
    return retValue;
  }
}
