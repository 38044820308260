import { AuthModel } from './../../model/auth.model';
import { LoggerModel } from './../../model/logger.model';
import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import {  GoogleRedirectionStorageAction, GuestUserAction, IntroVisitAction, LoginResponseAction, LogReqAction, TokenStorageAction, UserDetailAction,  } from "./auth.actions";


export const initialTokenState : AuthModel = {
  userDetails: null,
  token : null ,
  loginResponse : null,
  userPlan: null,
  isIntroSlidesVisited: false,
  isGuestUser : false,
  isRedirectedToGoogle : false
}

export const STATE_TOKEN = new StateToken<AuthModel>('auth');

@State({
  name: STATE_TOKEN,
  defaults: initialTokenState,
})

@Injectable()
export class AuthState
{
  constructor()
  {

  }
  @Action(TokenStorageAction)
  setToken(ctx : StateContext<AuthModel>, action : TokenStorageAction)
  {
    ctx.patchState(
      {
        token : action.token?action.token:null
      }
    );
  }

  @Action(LoginResponseAction)
  setLoginResponse(ctx : StateContext<AuthModel> , action : LoginResponseAction)
  {
    ctx.patchState(
      {
        loginResponse : action.loginResponse
      }
    );
  }




  @Action(IntroVisitAction)
  setIsIntroSlidesVisited(ctx : StateContext<AuthModel> , action : IntroVisitAction)
  {
    ctx.patchState(
      {
        isIntroSlidesVisited : action.introslides
      }
    );
  }

  @Action(UserDetailAction)
  setUserDetails(ctx : StateContext<AuthModel> , action : UserDetailAction)
  {
    ctx.patchState(
      {
        userDetails : action.user
      }
    );
  }

  @Action(GuestUserAction)
  setGuestUser(ctx : StateContext<AuthModel> , action : GuestUserAction )
  {
    ctx.patchState(
      {
        isGuestUser : action.isGuestUser
      }
    );
  }

  @Action(GoogleRedirectionStorageAction)
  setGoogleRedirection(ctx : StateContext<AuthModel> , action : GoogleRedirectionStorageAction )
  {
    ctx.patchState(
      {
        isRedirectedToGoogle : action.googleRedirection
      }
    );
  }

}


export const LoggerReqInitialState: LoggerModel = {
  logRequest: null
}
export const LOG_REQUEST = new StateToken<LoggerModel>('logRequest');
@State({
  name: LOG_REQUEST,
  defaults: LoggerReqInitialState,
})

@Injectable()
export class LogReqState {
constructor( ) {}

@Action(LogReqAction)
getLoggerState(ctx: StateContext<LoggerModel>, action: LogReqAction)
{ 
  ctx.patchState({ logRequest: action.logReq})
}
}