import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertButton, AlertController, ModalController, NavController, ToastController } from "@ionic/angular";
import { Store } from '@ngxs/store';
import cloneDeep from 'lodash/cloneDeep';
import { CustomModalComponent } from '../components/custom-modal-component/custom-modal-component.component';
import { GuestUserComponent } from '../components/guest-user/guest-user.component';
import { ClearStoreFunction } from '../core/store/clearStore';
import { ErrorAlertVal, Status } from '../model/Response.model';
import { AlertModalData, AlertModalStatus } from '../model/alert-modal.model';
import { LOG_TYPE } from '../model/logger.model';
import { getUserDetails } from '../store/auth/auth.selectors';
import { LoggerService } from './../services/logger/logger.service';
import { BASE_CONFIG } from "./base-settings";
import { APP_ROUTES, BillType, FILE_NAME, ROUTER_PARAM } from "./constants";

@Injectable({
  providedIn: 'root',
})
export class UtilFunctions {

  private isSessionExpired: boolean = false;
  private modalCount = 0;

  constructor(
     private navController: NavController, 
     private logger: LoggerService,
     private store :Store, 
     private modalController: ModalController,
     private router: Router, 
     private toastController:ToastController,
     private alertController:  AlertController,
     private clearStore : ClearStoreFunction ,){}

  


  /**
   * The function is used to navigate to a page with query params
   * @param pUrl - The URL you want to navigate to.
   * @param [pQueryParams] - This is the query parameters that you want to pass to the next page.
   * @param [state] - This is an object that you can pass to the next page.
   * @param [replaceUrl] - If true, the navigation will replace the current URL in the history stack
   * instead of adding a new one.
   */
  async navigate(pUrl , pQueryParams? , state?,replaceUrl? ){
    try{
      if (BASE_CONFIG.IS_DEBUG) console.log("navigate");
      
       await this.navController.navigateRoot([pUrl],{queryParams : pQueryParams,state:state,replaceUrl:replaceUrl});
  
    }catch (err : any) {
        let logRequest = this.logger.buildRequest(
            FILE_NAME.UTIL,
            "error in navigate",
            err.toString(),
            "navigate",
          );
          this.logger.log(logRequest);
    }
  }

  
 /**
  * It checks if the error is a JWT error, if it is, it gets the current user's token and dispatches it
  * to the store. If it's not a JWT error, it navigates to the login page.
  * @param pError - The error object that is returned from the server.
  * @param [pDonotNav] - boolean - if true, the function will not navigate to the login page.
  * @returns The return value is a boolean.
  */
 handleJWT(pError, pDonotNav?)
  {
    let retValue: boolean = false;

    try
    {

      if (pError)
      {
        let queryParams = {} as any;
        let isJwt = false;
        if (pError.error)
        {
          for (let index = 0; index < pError.error.length; index++)
          {
            const err = pError.error[index];
            if (err.code == Status.ERROR_JWT_01 ||
              err.code == Status.ERROR_JWT_02 ||
              err.code == Status.ERROR_AUT2 ||
              err.code == Status.ERROR_PER)
            {
              queryParams[ROUTER_PARAM.PARAM] = [err.code, err.value];
              isJwt = true;

            }
            
          }
        }

        if (isJwt)
        {

          retValue = true;
          if (!pDonotNav)
          {
            if (queryParams.param.length > 0)
            {
              let sLogin = this.store.select(getUserDetails()).subscribe(async (loginResponse) =>
              {
                if (loginResponse)
                {
                  // let error =   this.getErrorAlertValue(pError, true, false)
                  await this.navToLogin(queryParams);
                }
              });

              sLogin.unsubscribe();
            } else
            {

              retValue = false;
            }
          }
        }
      }

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in handleJWT",
        err.toString(),
        "handleJWT",
      );
      this.logger.log(logRequest);
    }
    return retValue;
  }

 /**
  * It's a function that navigates to the login page.
  * @param [queryParams] - {
  */
  async navToLogin(queryParams?)
  // :Promise<boolean>
  {
    // let retValue: boolean = false;

    try
    {
      this.clearStore.toClearStore();
      this.router.navigate([ APP_ROUTES.LOGIN ], { queryParams: { code: queryParams.param[ 0 ], error: queryParams.param[ 1 ] }, replaceUrl: true, state: { allow: true } });
      // this.modalCount = 0;
      // let modalcontroller= await this.modalController.getTop();
      //  if(modalcontroller) {
      //   this.modalController.dismiss()
      //  }

      //   let modalData: AlertModalData = {
      //     buttonName: [$localize`:@@alertModal.okBtn:OK`],
      //     desc: queryParams && queryParams.param.length>1 ? queryParams.param[1] : $localize`:@@common-sessionExpireText:Your session has expired. Please login again to continue.`,
      //     status: AlertModalStatus.failed,
      //     title: $localize`:@@sessionExpire-title:Session Expired`,
      //   };

      //   var modal = await this.modalController.create({
      //     component: CustomModalComponent,
      //     id: 'jwt-modal',
      //     componentProps: {
      //       data: modalData,
      //       isJwtModal: true,
      //       emitFunc: await this.getConfirm.bind(this, queryParams.param)
      //     },
      //     backdropDismiss: false,
      //     cssClass:'sess-modal',
      //   });
      //   await modal.present();
        
    
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in navToLogin",
        err.toString(),
        "navToLogin",
      );
      this.logger.log(logRequest);
    }
    // return retValue;
  }

  private async getConfirm(pQueryParams)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log('getConfirm');
      // let modal = await this.modalController.getTop();
      await this.modalController.dismiss('jwt-modal');
      // let id = modal ? modal.id : null;
      // if (id && this.modalCount < 3)
      // {
      //   await this.modalController.dismiss().then(async () =>
      //   {
      //     this.modalCount++;
      //     await this.getConfirm(pQueryParams);
      //   });
      // }
      if (this.isSessionExpired) {
        this.clearStore.toClearStore();
        this.router.navigate([ APP_ROUTES.LOGIN ], { queryParams: { code: pQueryParams[ 0 ], error: pQueryParams[ 1 ] }, replaceUrl: true, state: { allow: true } });
      }
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in getConfirm",
        err.toString(),
        "getConfirm",
      );
      this.logger.log(logRequest);
    }
  }

/**
  To display a toast
 * @param {string} pMsg - string - The message to display in the toast
 * @param {string} pToastType - string - This is the color of the toast.
 * @param {string} [pButtonTxt] - The text of the button.
 * @param {number} [pDuration] - number - The duration of the toast in milliseconds. Default is 2000.
 * @param {string} [pPath] - The path to navigate to.
 * @param {boolean} [isNeedBtn=false] - boolean = false,
 * @param [pIsNavFirst] - boolean - if true, it will navigate to the path first before showing the
 * toast.
 */
     async toShowToast(pMsg: string, pToastType: string, pIconName?: string, pButtonTxt?: string, pDuration?: number, pPath?: string, isNeedBtn: boolean = false, pIsNavFirst?)
     {
       try
       {
         if (BASE_CONFIG.IS_DEBUG) console.log("toShowToast");
   
         if (BASE_CONFIG.IS_DEBUG) console.log("toShowToast");

         if (pIsNavFirst)
         {
           // await this.navigate([pPath],{ state: { allow: true }})
           await this.navigate('/' + pPath, null, { state: { allow: true } });
         }
   
         let button: any[] = [];
         if (isNeedBtn)
         {
           button = [
             {
               text: pButtonTxt ? pButtonTxt : $localize`:@@util.toastBtnOK:OK`,
               handler: () =>
               {
               }
             }
           ];
         }
         let toast = await this.toastController.create({
           message: pMsg,
           duration: pDuration ? pDuration : 2000,
           icon: pIconName,
           cssClass: 'toast-custom-class',
           position: 'bottom',
           color: pToastType,
           buttons: button
         });
   
         if (pPath)
         {
   
           toast.onDidDismiss().then(() =>
           {
             if (!pIsNavFirst)
             {
               this.router.navigate([ pPath ], { state: { allow: true } });
             }
           });
         }
   
         await toast.present();
       }
       catch (err)
       {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.UTIL,
          "error in navToLogin",
          err.toString(),
          "navToLogin",
        );
        this.logger.log(logRequest);
       }
     }


     /**
      * It takes in a component name, a function name, and an error object, and returns a log request
      * object
      * @param pComponent - The component name
      * @param pFunction - The name of the function where the error occurred.
      * @param pError - The error object that is passed to the function
      * @returns The returnValue is being returned.
      */
     createLogRequest(pComponent, pFunction, pError)
     {
       let errorObj = 'No error object is passed'
       let returnValue;
       try
       {
         if(pError) {
           if(typeof pError != 'string') {
           if(pError.message && typeof pError.message === 'string') {
             errorObj = pError.message
           }else if(pError.message && typeof pError.message != 'string'){
             errorObj = JSON.parse(JSON.stringify(pError.message))
           }else{
             errorObj = JSON.parse(JSON.stringify(pError))
           }
         }else if(typeof pError === 'string'){
           errorObj = pError
         }
       }else{
         errorObj = 'No error object is passed'
         }
         returnValue = this.logger.buildRequest(
           pComponent,
           "error in " + pFunction,
            errorObj,pFunction, pError.status
         );
       } catch (err)
       {
         let logRequest = this.logger.buildRequest(
           FILE_NAME.UTIL,
           "error in createLogRequest",
           err.toString(),
           "createLogRequest",
         );
         this.logger.log(logRequest);
       }
       return returnValue;
     }


       // alert modal
  /**
   * It creates an alert modal with the given header, message, and buttons.
   * @param {string} pHeader - string - The header of the alert modal
   * @param {string} pMsg - string - The message to display in the alert.
   * @param {AlertButton[]} pButtons - AlertButton[]
   */
  async openAlertModal(pHeader: string, pMsg: string, pButtons: AlertButton[])
  {
    try
    {
      const alert = await this.alertController.create({
        cssClass: 'custom_alert_modal',
        header: pHeader,
        message: pMsg,
        buttons: pButtons
      });

      await alert.present();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        'error in openAlertModal',
        err.toString(),
        'openAlertModal',
      );
      this.logger.log(logRequest);
    }
  }

  /**
   * It's a wrapper around the cloneDeep function from the lodash library.
   * @param pValue - The value to be cloned
   * @returns The return value is the value of the last expression evaluated in the try block.
   */
  cloneDeep(pValue) {
   
    let retValue;

    try {
        retValue = cloneDeep(pValue)
    } catch (err) {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.UTIL,
           "error in cloneDeep",
           err.toString(),
            "cloneDeep",
             );
             this.logger.log(logRequest);
    }
    return retValue;
}

/**
 * It opens a link in a new tab.
 * @param pLink - The link to open
 */
onOpenLink(pLink) {
  try {
      window.open(pLink);
  } catch (err : any) {
      let body = this.logger.buildRequest(
        FILE_NAME.UTIL,
          "error while opening link",
          err.toString(),
          "onOpenLink",
      );
      this.logger.log(body);
  }
}

/**
 * If the current route is one of the three routes listed, navigate to the route listed, otherwise, go
 * back.
 */
 goBack()
{
  try {
    if (BASE_CONFIG.IS_DEBUG) console.log("goBack");

    // let url = this.router.url.split("?")[ 0 ];
    // switch (url) {
      
    
    //   default:
        this.navController.back();
    //     break;
    // }
  } catch (err) {
    let body = this.logger.buildRequest(
      FILE_NAME.UTIL,
        "error in goBack",
        err.toString(),
        "goBack",
    );
    this.logger.log(body);
  }
}




  handleErrorStatus(fileName: string, functionName: string, errorMessage: string)
  {
    try
    {
      // let modalData: AlertModalData = {
      //   buttonName: "OK",
      //   desc: "Something went wrong !",
      //   status: AlertModalStatus.failed,
      //   title: null,
      // };

      // const dialogRef = this.dialog.create({
      //   component: CustomDialogComponent,
      //   componentProps: {
      //     data: modalData,
      //   },
      //   // closeOnBackdropClick: false,
      // });
      // dialogRef.onClose.subscribe((isConfirm) => {

      //   if(!isConfirm)
      //   {
      //     this.logOutService.apiLogoutRes().subscribe({
      //       next : val => {
      //         this.storeClear.toClearStore();
      //         this.router.navigate([APP_ROUTES.LOGIN]);
      //       },
      //       error : (error : HttpErrorResponse) => {
      //         this.storeClear.toClearStore();
      //         this.router.navigate([APP_ROUTES.LOGIN]);
      //         let logRequest = this.logger.buildRequest(
      //           FILE_NAME.UTIL,
      //           "error in doLogout",
      //           error.message.toString(),
      //           "doLogout"
      //         )
      //         this.logger.log(logRequest);
      //       }
      //     })
      //   }
      //   else{
      //   // this.onDialogClose.emit();
      //   }
      // });
      const logRequest = this.logger.buildRequest(
        fileName,
        "error in " + functionName,
        errorMessage,
        functionName
      );
      this.logger.log(logRequest);

    } catch (error)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in handleErrorStatus",
        error.toString(),
        "handleErrorStatus"
      );
      this.logger.log(logRequest);
    }
  }

  logFunc(funcName , error , fileName , logType?)
  {
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log('logFunc');
      
      let logRequest = this.logger.buildRequest( fileName , 'error in ' + funcName , error , funcName , null ,  logType? logType : LOG_TYPE.ERROR) ;
      this.logger.log(logRequest);
    } catch (error) {
      this.logFunc('logFunc','error in logFunc','util logFunc');
    }
  }

  async toShareURL(pTitle: string, pText: string, pUrl: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("toShareURL");

      let shareRet = {
        title: pTitle,
        text: pText,
        url: pUrl
      };
      await navigator.share(shareRet);
    }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in toShareURL",
        error.toString(),
        "toShareURL"
      );
      this.logger.log(logRequest);
    }
  }

  async errorHandling(pError)
  {
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("errorHandling");
      const errorCode: string = pError[ 0 ].extensions.code;
      if (errorCode == Status.SESSION_EXPIRE) {
        this.isSessionExpired = true;
      }else{
        this.isSessionExpired=false;
      }
      if (pError.length)
      {
        let modalData: AlertModalData = {
          buttonName: $localize`:@@util.toastBtnOK:OK`,
          desc: this.isSessionExpired ? $localize`:@@util.sessionExpiredDesc:Your session has expired. Please Login again to continue.` : $localize`:@@util.failed:Failed`,
          status: AlertModalStatus.failed,
          title: this.isSessionExpired ? $localize`:@@util.sessionExpired:Session Expired` : $localize`:@@util.failed:Failed`,
        };
        const dialogRef = await this.modalController.create({
          component: CustomModalComponent,
          id: 'jwt-modal',
          cssClass: 'auto-height',
          componentProps: {
            data: modalData,
            isJwtModal: true,
            emitFunc: await this.getConfirm.bind(this)
          },
          backdropDismiss: false,
        });
        await dialogRef.present();
      }
    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in errorHandling",
        error.toString(),
        "errorHandling"
      );
      this.logger.log(logRequest);
    }
  }

  getStatusColor (pStatus) {
    let retValue;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log('getStatusColor');
      switch (pStatus) {
        case BillType.OVERDUE:
          retValue = '#E5001C';
          break;
        case BillType.UPCOMING:
          retValue = '#407BFF';
          break;
        case BillType.PAID:
          retValue = '#2FAA58'
          break;
        case BillType.TODAY:
          retValue = '#FF6B00';
          break;
        case BillType.UNPAID:
          retValue = '#dbb118';
          break;
      
        default:
          break;
      }
    } catch (error) {
      this.logFunc('getStatusColor', error, FILE_NAME.UTIL);
    }
    return retValue;
  }

  getTypeColor (pType) {
    let retValue;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log('getTypeColor');
      switch (pType) {
        case BillType.PAYABLE:
          retValue = '#2FAA58';
          break;
        case BillType.RECEIVABLE:
          retValue = '#E5001C';
          break;
      
        default:
          break;
      }
    } catch (error) {
      this.logFunc('getTypeColor', error, FILE_NAME.UTIL);
    }
    return retValue;
  }

  getColor(pCategoryId) {
    let retValue;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log('getColor');

      switch (pCategoryId) {
        case -3:
          retValue = ['#468BEB','#468BEB10'];
          break;

        case -1:
          retValue = ['#D12F2C','#D12F2C10'];
          break;

        case -2:
          retValue = ['#E3912D','#E3912D10'];
          break;

        case -4:
          retValue = ['#D97307','#D9730710'];
          break;

        case -5:
          retValue = ['#2BA62F','#2BA62F10'];
          break;
        case 1:
          retValue = ['#054C9D','#054C9D10'];
          break;
        case 2:
          retValue = ['#1496CE','#1496CE10'];

          break;
        case 3:
          retValue = ['#C42F3D','#C42F3D10'];
          break;
        case 4:
          retValue = ['#D0DD37','#D0DD3710'];
          break;
        case 5:
          retValue = ['#EA7C03','#EA7C0310'];
          break;
        case 6:
          retValue = ['#972EC4','#972EC410'];
          break;
        case 7:
          retValue = ['#AB4189','#AB418910'];
          break;
        case 8:
          retValue = ['#FEE5AD','#FEE5AD10'];
          break;
        case 9:
          retValue = ['#24466B','#24466B10'];
          break;
        case 10:
          retValue = ['#EDBF9D','#EDBF9D10'];
          break;
        case 11:
          retValue = ['#149D05','#149D0510'];
          break;
        case 12:
          retValue = ['#EA0B03','#EA0B0310'];
          break;
        case 13:
          retValue = ['#E9C615','#E9C61510'];
          break;
        case 14:
          retValue = ['#B9DC82','#B9DC8210'];
          break;
        case 15:
          retValue = ['#A5548C','#A5548C10'];
          break;
        case 16:
          retValue = ['#D773B3','#D773B310'];
          break;
        case 17:
          retValue = ['#3BA6A0','#3BA6A010'];
          break;
        case 18:
          retValue = ['#A0C663','#A0C66310'];
          break;
        case 19:
          retValue = ['#FFC184','#FFC18410'];
          break;
        case 20:
          retValue = ['#D16C34','#D16C3410'];
          break;
        case 21:
          retValue = ['#FF9A96','#FF9A9610'];
          break;
        case 22:
          retValue = ['#D9EAFA','#D9EAFA10'];
          break;
        case 23:
          retValue = ['#0AF5F1','#0AF5F110'];
          break;

        ///random color
        default:
          retValue = ['#ff2ba62f','#ffd12f2c'];

          break;
      }
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.CATEGORY_COLOR_DIRECTIVE,
        'error in getColor',
        err.toString(),
        'getColor'
      );
      this.logger.log(logRequest);
    }
    return retValue;
  }
  async openGuestUserModal()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("openGuestUserModal");
    // alert('Login & continue')
      // this.closeModal();

      let modal = await this.modalController.create({
        component: GuestUserComponent,
        // swipeToClose: true,
        initialBreakpoint: 1,
        breakpoints: [0],
        componentProps: {
          isGuestFlow: true,
          isIntroSkipped: false,
          isIntroSlidesVisited: true,
          closeModal: this.closeModal.bind(this),
        },
      });
      await modal.present();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in openGuestUserModal",
        err.toString(),
        "openGuestUserModal",
      );
      this.logger.log(logRequest);
    }
  }
  async closeModal()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("closeModal");

      const isModalOpened = await this.modalController.getTop();
      if (isModalOpened)
      {
        await isModalOpened.dismiss();
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in closeModal",
        err.toString(),
        "closeModal",
      );
      this.logger.log(logRequest);
    }
  }

  getErrorAlertValue(pError, pIsNeedCode, isAlertExist): ErrorAlertVal
  {
    let errorAlert: ErrorAlertVal = {
      codeList: [],
      valueList: []
    };

    try
    {
      if (pError && pError.error && pError.error.length)
      {

        if (!(true))
        {
          for (let index = 0; index < pError.error.length; index++)
          {
            if (pError.error[index].value)
            {
              errorAlert.valueList.push(pError.error[index].value);
            }
          }
        }

        else if (true)
        {
          for (let index = 0; index < pError.error.length; index++)
          {
            if (pError.error[index].code)
            {
              errorAlert.codeList.push(pError.error[index].code);
            }
            if (pError.error[index].value)
            {
              errorAlert.valueList.push(pError.error[index].value);
            }
          }
        }
        let logRequest = this.logger.buildRequest(
          FILE_NAME.UTIL,
          "error in getErrorAlertValue",
          JSON.stringify(pError),
          "getErrorAlertValue",
        );
        this.logger.log(logRequest);
      }

      else
      {
        errorAlert.valueList = ["Failed"];
      }

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in getErrorAlertValue",
        err.toString(),
        "getErrorAlertValue",
      );
      this.logger.log(logRequest);
    }

    return errorAlert;
  }
}




