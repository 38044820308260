import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FirebaseAuthentication } from '@awesome-cordova-plugins/firebase-authentication';
import { Store } from '@ngxs/store';
import 'firebase/auth';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, Observable, from, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BASE_CONFIG } from '../../util/base-settings';
import { SERVICE_URL, ZACE } from '../../util/constants';
import { TokenStorageAction } from './../../store/auth/auth.actions';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  BASE_URL=null
  // isAdminFlow: boolean = environment.isAdmin;
  constructor(private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any | HttpEvent<any>> {
     
      this.BASE_URL = environment.apiUrl
    if (
        request.url == this.BASE_URL + SERVICE_URL.APP_AUTH_URL ||
        request.url == ZACE.BASE_URL + SERVICE_URL.API_SEND_EMAIL
      ) {
        return next.handle(request);
      }else{
        this.tokenSubject.next(null);
        
        return from(this.getToken()).pipe(
          switchMap((newToken: any) => {
            if (newToken) {
              this.tokenSubject.next(newToken);
              request = request.clone({
                setHeaders: { Authorization: `Bearer ${newToken}` },
              });
              return next.handle(request);
            }

            
          }),
        );
      }
    
    
   
  }


  getToken() {
    if (!BASE_CONFIG.IS_WEB) {
      return new Promise((resolve, reject) => {
        FirebaseAuthentication.getCurrentUser().then(async (res) => {
            if (res) {
              let idToken = await FirebaseAuthentication.getIdToken(true);
              this.store.dispatch(new TokenStorageAction(idToken));
              resolve(idToken)
            }else{
              const auth = firebase.auth();
              const unsubscribe = auth.onIdTokenChanged(user => {
                unsubscribe();
                if (user) {
                  user.getIdToken().then(token => {
                      this.store.dispatch(new TokenStorageAction(token));
                    resolve(token);
                  });
                } else {
                  reject(null);
                }
              }, reject);
            }
          });
      });
      
    } else {
    
      return new Promise((resolve, reject) => {
        const auth = firebase.auth();
        const unsubscribe = auth.onIdTokenChanged(user => {
          unsubscribe();
          if (user) {
            user.getIdToken().then(token => {
                this.store.dispatch(new TokenStorageAction(token));
              resolve(token);
            });
          } else {
            reject(null);
          }
        }, reject);
      });
   
    }
  }
}
