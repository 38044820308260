import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import
  {
    AngularFirestore,
  } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { FirebaseAuthentication } from '@awesome-cordova-plugins/firebase-authentication';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { AlertButton, LoadingController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { getAuth, signInWithRedirect } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { lastValueFrom } from 'rxjs';
import { ClearStoreFunction } from 'src/app/core/store/clearStore';
import { User } from './../../model/user.model';
import { AuthService } from './../../services/auth/auth.service';
import { LoggerService } from './../../services/logger/logger.service';
import { GoogleRedirectionStorageAction, GuestUserAction, TokenStorageAction, UserDetailAction } from './../../store/auth/auth.actions';
import { BASE_CONFIG } from './../../util/base-settings';
import { AUTH_KEY, AppleSignIn, FB_AUTH_TYPE, FILE_NAME, HOST, PATHNAME, PLATFORM, Provider, SOCIAL_LOGIN_TYPE, ToastTypes } from './../../util/constants';
import { UtilFunctions } from './../../util/util';

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticationService {

  // userData: any; // Save logged in user data
  // isWeb: boolean = BASE_CONFIG.IS_WEB;
  // isIOS: boolean = BASE_CONFIG.PLATFORM == PLATFORM.ios;
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning,
    private apiFirebaseAuthService: AuthService,
    private store: Store,
    private logger: LoggerService,
    private util: UtilFunctions,
    private clearStore : ClearStoreFunction,
    private loader: LoadingController
  ) { 
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    // this.afAuth.authState.subscribe((user) => {
    //   if (user) {
    //     this.userData = user;
    //     this.setUserData(user);
    //   } else {
    //     // this.store.dispatch(new UserDetailAction(null));
    //   }
    // });
  }


  // Sign in with email/password
  async signInWithEmailAndPwd(email: string, password: string) {
    var  token, isValid;
    try
      {
        if (BASE_CONFIG.IS_DEBUG) console.log("signInWithEmailAndPwd");

        await this.afAuth.signInWithEmailAndPassword(email, password).then(
          async (result) =>
          {
            if(result.user.emailVerified){
     
              token = await result.user.getIdToken()
              isValid = await this.firebaseAuthApi(token)
               await  this.setUserData(result.user);
               }else{
                this.loader.dismiss(undefined, undefined, 'sign_in_loader');
                this.util.toShowToast($localize`:@@user-auth-emailVerify:Please verify your email for Bills Reminder App which has been sent to your registered mail id.`,ToastTypes.Success);
               }
  
          },
          (error) =>
          {
            isValid = {
              message: error
            };
          })
          .catch((error) =>
          {
            isValid = {
              message: error
            };
          });


     
      } catch (err: any)
      {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.USER_AUTHENTICATION_SERVICE,
          "error in signInWithEmailAndPwd",
          err.toString(),
          "signInWithEmailAndPwd",
        );
        this.logger.log(logRequest);
      }
      return isValid;
    }
  

  // Sign up with email/password
  async signUpWithEmailAndPwd(email: string, password: string) {
    var  isCreated;
    try
      {
        if (BASE_CONFIG.IS_DEBUG) console.log("signInWithEmailAndPwd");
        await this.afAuth.createUserWithEmailAndPassword(email, password).then(
          async res => {
            if(res){
               /* Call the SendVerificaitonMail() function when new user sign 
                  up and returns promise */
                  let isCurrentUser = await this.afAuth.currentUser
                  await isCurrentUser.sendEmailVerification().then(res => {
                    return true;
                  })
                  isCreated = true
                  await this.setUserData(res.user);
              }
          }).catch(err => {
            isCreated ={
              message: err
            }
          })
       
    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_AUTHENTICATION_SERVICE,
        "error in signInWithEmailAndPwd",
        err.toString(),
        "signInWithEmailAndPwd",
      );
      this.logger.log(logRequest);
    }
    return isCreated;
  }


  // Reset Forggot password
  async forgotPassword(passwordResetEmail: string) {
    var  isSent;
    try
      {
        if (BASE_CONFIG.IS_DEBUG) console.log("signInWithEmailAndPwd");
    await this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        isSent = true
        this.util.toShowToast($localize`:@@user-auth-passReset:Password reset email sent, check your inbox.`,ToastTypes.Success);
      })
      .catch((error) => {
        isSent = {
          message : error
        }
      });
    } catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_AUTHENTICATION_SERVICE,
        "error in signInWithEmailAndPwd",
        err.toString(),
        "signInWithEmailAndPwd",
      );
      this.logger.log(logRequest);
    }
    return isSent;
  }






  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
 async setUserData(user: any) {
    // const userRef: AngularFirestoreDocument<any> = this.afs.doc(
    //   `users/${user.uid}`
    // );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    // return userRef.set(userData, {
    //   merge: true,
    // });
    await lastValueFrom(this.store.dispatch(new UserDetailAction(userData)));
    return userData
  }


  // Sign out
  async signOut() {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("signOut");
    if(BASE_CONFIG.IS_WEB){
      await this.afAuth.signOut().then(() => {
         this.store.dispatch(new UserDetailAction(null));
         this.clearStore.toClearStore();
         this.router.navigate([PATHNAME.LOGIN], {  replaceUrl: true, state: { allow: true } });
       });
     }else{
        FirebaseAuthentication.getCurrentUser().then(async (res) => {
          if (res) {
            await FirebaseAuthentication.signOut();
          }else{
            await this.afAuth.signOut();
          }
        });
       this.store.dispatch(new UserDetailAction(null));
       this.clearStore.toClearStore();
       this.router.navigate([PATHNAME.LOGIN], {  replaceUrl: true, state: { allow: true } });
     }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_AUTHENTICATION_SERVICE,
        "error in signOut",
        err.toString(),
        "signOut",
      );
      this.logger.log(logRequest);
    }
  }


    ///on social type login

    async onSocialLogin(loginProvider):Promise<boolean>
    {
      // let isValidLogin=false;
      try
      {
        if (BASE_CONFIG.IS_DEBUG) console.log("onSocialLogin");
      var provider: firebase.auth.AuthProvider;
      if (loginProvider === SOCIAL_LOGIN_TYPE.GOOGLE)
      {
        provider = new firebase.auth.GoogleAuthProvider();
        if(BASE_CONFIG.IS_WEB){
          return await this.loginWithGoogleProvider(
            provider,
            FB_AUTH_TYPE.SIGNIN_WITH_REDIRECT,
          );
        }else{
          return await this.loginWithGoogleProvider(
            provider,
            FB_AUTH_TYPE.SIGNIN_WITH_POPUP,
          );
        }
        
      }
      else if (loginProvider === SOCIAL_LOGIN_TYPE.FACEBOOK)
      {
        provider = new firebase.auth.FacebookAuthProvider();
        // let signInRes =  this.afAuth.signInWithPopup(provider);

        const auth = getAuth();
        signInWithRedirect(auth, provider)
          .then((result) => {
            // The signed-in user info.
            // const user = result.user;
        
            // // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            // const credential = FacebookAuthProvider.credentialFromResult(result);
            // const accessToken = credential.accessToken;
        
            // IdP data available using getAdditionalUserInfo(result)
            // ...
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            // const credential = FacebookAuthProvider.credentialFromError(error);
        
            // ...
          });
        // let signInRes =  this.afAuth.signInWithPopup(provider);

      }
      else if (loginProvider === SOCIAL_LOGIN_TYPE.TWITTER)
      {
        provider = new firebase.auth.TwitterAuthProvider();
        let signInRes =  this.afAuth.signInWithPopup(provider);

      }
      else if (loginProvider === SOCIAL_LOGIN_TYPE.APPLE) {
        let isValidLogin;
        const appleProvider: firebase.auth.OAuthProvider = new firebase.auth.OAuthProvider(Provider.APPLE);
        if (BASE_CONFIG.IS_WEB) {
          return await this.loginWithAppleProvider(appleProvider, FB_AUTH_TYPE.SIGNIN_WITH_POPUP);
        } else if (BASE_CONFIG.PLATFORM == PLATFORM.ios) {
          let options: SignInWithAppleOptions = {
            clientId: AppleSignIn.CLIENT_ID,
            redirectURI: AppleSignIn.REDIRECT_URI,
            scopes: AppleSignIn.SCOPES,
            state: AppleSignIn.STATE
          };

          return SignInWithApple.authorize(options).then(async (result: SignInWithAppleResponse) =>
          {
            const appleProvider = new firebase.auth.OAuthProvider(Provider.APPLE);
            const credential = appleProvider.credential({
              idToken: result.response.identityToken,
            });
            const userCredential = await this.afAuth.signInWithCredential(credential);                      //To get Firebase Token
            let idToken = await userCredential.user.getIdToken();
            isValidLogin = await this.firebaseAuthApi(idToken);
            if ((userCredential.user.email).includes(HOST.APPLE_DUMMY))
            {
              this.toShowAppleAlert();
            }
            this.setUserData(userCredential.user);
            return isValidLogin;
          });
        }
      }
    }
      catch (err)
      {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.USER_AUTHENTICATION_SERVICE,
          "error in onSocialLogin",
          err.toString(),
          "onSocialLogin",
        );
        this.logger.log(logRequest);
      }
      // return isValidLogin;
    }

    toShowAppleAlert()
    {
      try {
        if (BASE_CONFIG.IS_DEBUG) console.log("toShowAppleAlert");
        let buttons: AlertButton[] = [
          {
            text: $localize`:@@userAuthentication-ok:OK`,
            handler: async () =>
            {
            }
          }
        ];
        this.util.openAlertModal("", $localize`:@@userAuthentication-emailWarning:As you have opted 'Hide My Email' feature during sign-in, retrieval of original mail id account's bills is not be possible. To get your bills, Kindly sign in to Bills Reminder with your email. or You can continue use the App by choosing "Hide my Email".`, buttons);
      } catch (error) {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.USER_AUTHENTICATION_SERVICE,
          "error in toShowAppleAlert",
          error.toString(),
          "toShowAppleAlert",
        );
        this.logger.log(logRequest);
      }
    }
  
    /// login with social provider

    async loginWithAppleProvider(appleProvider: firebase.auth.OAuthProvider, pAuthType: string): Promise<boolean>
    {
      try {
        if (BASE_CONFIG.IS_DEBUG) console.log("loginWithAppleProvider");

        switch (pAuthType)
        {
          case FB_AUTH_TYPE.SIGNIN_WITH_POPUP:
            let isValidLogin;
            return this.afAuth.signInWithPopup(appleProvider).then(async(result: firebase.auth.UserCredential) => {
              let idToken = await result.user.getIdToken();
              isValidLogin = await this.firebaseAuthApi(idToken);
              if ((result.user.email).includes(HOST.APPLE_DUMMY))
              {
                this.toShowAppleAlert();
              }
              this.setUserData(result.user);
              return isValidLogin
            }).catch((error) =>
            {
              this.loader.dismiss(undefined, undefined, 'google_login_loader');
            });
            break;
  
          case FB_AUTH_TYPE.SIGNIN_WITH_REDIRECT:
            this.afAuth.signInWithRedirect(appleProvider);
            break;
          default:
            break;
        }

      } catch (error) {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.USER_AUTHENTICATION_SERVICE,
          "error in loginWithAppleProvider",
          error.toString(),
          "loginWithAppleProvider",
        );
        this.logger.log(logRequest);
      }
    }
  
    async loginWithGoogleProvider(provider: firebase.auth.AuthProvider, pAuthType: string):Promise<boolean>
    {
      let isValidLogin:boolean;
      try
      {
        if (BASE_CONFIG.IS_DEBUG) console.log("loginWithProvider");
  
        switch (pAuthType)
        {
          case FB_AUTH_TYPE.SIGNIN_WITH_POPUP:
  
            if (BASE_CONFIG.IS_WEB)
            {  //web
  
              let signInRes = await this.afAuth.signInWithPopup(provider);
              if (signInRes)
              {
                // let idToken = await signInRes.user.getIdToken();
                let accessToken =signInRes.user['auth']._currentUser.accessToken;
                await lastValueFrom(this.store.dispatch(new TokenStorageAction(accessToken)));

                isValidLogin = await this.firebaseAuthApi(accessToken);
                await this.setUserData(signInRes.user);
              }
  
            } else
            {  //mbl
              await GoogleAuth.signOut();
             await GoogleAuth.signIn().then(async googleUser =>
              {
                const credential = firebase.auth.GoogleAuthProvider.credential(googleUser.authentication.idToken);
                let res = await FirebaseAuthentication.signInWithGoogle(credential.idToken, credential.accessToken);
  
                if (res == "OK" || (BASE_CONFIG.PLATFORM == PLATFORM.ios && res == null))
                {
                  let idToken = await FirebaseAuthentication.getIdToken(false);
                  isValidLogin = await this.firebaseAuthApi(idToken);
// console.error(googleUser,credential,await FirebaseAuthentication.getCurrentUser())
                  // let user = {
                  //   uid : googleUser.id,
                  //   email: googleUser.email,
                  //   displayName: googleUser['displayName'],
                  //   photoURL: googleUser.imageUrl,
                  //   emailVerified: true,
                  // }
                  this.setUserData(await FirebaseAuthentication.getCurrentUser());

                }
              }).catch(err => {
                this.loader.dismiss(undefined, undefined, 'google_login_loader');
                // dnt need to handle it
              });
            }
  
            break;
  
          case FB_AUTH_TYPE.SIGNIN_WITH_REDIRECT:
            // if (BASE_CONFIG.IS_WEB)
            // {  //web

            await lastValueFrom(this.store.dispatch(new GoogleRedirectionStorageAction(true)));
               this.afAuth.signInWithRedirect(provider)
              //  .then(() => {
              //   console.error(provider)
                // this.ngZone.run(() => {
                //   console.error('sd');
                //   this.afAuth.getRedirectResult().then(result => {
                //     console.error(result);
                //     const that = this;
                   
                // }).catch(function (error) {
                //     alert(error.message);
                // });
                // })
             
              //  })
            // } else{
              
            // }
            break;
          default:
            break;
        }
      }
      catch (err)
      {
        let logRequest = this.logger.buildRequest(
          FILE_NAME.USER_AUTHENTICATION_SERVICE,
          "error in loginWithProvider",
          err.toString(),
          "loginWithProvider",
        );
        this.logger.log(logRequest);
      }
  
      return isValidLogin;
    }

      ///guest login
  async signInAnonymously()
  {
    let isValidLogin = false;
    let res: any = null;
    let idToken = null;

    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("signInAnonymously");

      this.store.dispatch(new GuestUserAction(true));

      if (BASE_CONFIG.IS_WEB)   //web
      {
        let user = await this.afAuth.signInAnonymously();

        if (user)
        {
          idToken = await user.user.getIdToken();
          isValidLogin = await this.firebaseAuthApi(idToken);
          await this.setUserData(user.user);

        }
      }
      else  //mbl
      {
        res = await FirebaseAuthentication.signInAnonymously();

        if (res == "OK" || (BASE_CONFIG.PLATFORM == PLATFORM.ios && res == null))
        {
          idToken = await this.generateToken();
          isValidLogin = await this.firebaseAuthApi(idToken);
          let user={
            displayName:'Guest User',
            email:'',
            photoURL:''
          }
          await this.setUserData(user);

        }
      }
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_AUTHENTICATION_SERVICE,
        "error in signInAnonymously",
        err.toString(),
        "signInAnonymously",
      );
      this.logger.log(body);
    }
    return isValidLogin;
  }

  //firebase auth api
  async firebaseAuthApi(pToken: string):Promise<boolean>
  {
    let isValid: boolean = false;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("firebaseAuthApi");

      // const retReq: any = this.apiFirebaseAuthService.apiGetAuthReq(pToken);

      if (pToken)
      {
        let authRes = await this.apiFirebaseAuthService.apiGetAuthRes(pToken);
        // let res = this.apiFirebaseAuthService.apiGetAuthRes(retReq)
        // res.subscribe(async authRes => {
        if (authRes)
        {
   

          if (authRes && (AUTH_KEY.ok == authRes.result))
          {
            let msg = authRes.msg;
     
            let jwtToken = "";

            if (msg == AUTH_KEY.newUserRefresh)
            {
              isValid = true;

              if (BASE_CONFIG.IS_WEB)
              {
                jwtToken = await firebase.auth().currentUser?.getIdToken(true);
                await lastValueFrom(this.store.dispatch(new TokenStorageAction(jwtToken)));
              }
              else
              {
                FirebaseAuthentication.getCurrentUser().then(async (res) => {
                  if (res) {
                    jwtToken = await FirebaseAuthentication.getIdToken(true);
                    await lastValueFrom(this.store.dispatch(new TokenStorageAction(jwtToken)));
                  }else{
                    jwtToken = await firebase.auth().currentUser?.getIdToken(true);
                    await lastValueFrom(this.store.dispatch(new TokenStorageAction(jwtToken)));
                  }
                });
              }
            }
            else if (msg == AUTH_KEY.existingUser)
            {
              isValid = true;
              await lastValueFrom(this.store.dispatch(new TokenStorageAction(pToken)));
            }
            else if (msg == AUTH_KEY.tokenExpired)
            {
              isValid = false;
            }
          }
          else
          {
            isValid = false;
          }
        }
        else
        {
          this.util.createLogRequest(FILE_NAME.USER_AUTHENTICATION_SERVICE,'firebaseAuthApi',authRes)
          this.util.toShowToast($localize`:@@user-auth-somethingWentWrong:Something went wrong. Please try again later`,ToastTypes.Failure);

          isValid = false;
        }
      }
    }
    catch (err: any)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.USER_AUTHENTICATION_SERVICE,
        "error in firebaseAuthApi",
        err.toString(),
        "firebaseAuthApi",
      );
      this.logger.log(logRequest);
    }
    return isValid;
  }
  ///generate token
  async generateToken()
  {
    let idToken = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("generateNewToken");

      idToken = await FirebaseAuthentication.getIdToken(false);
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.AUTH_SERVICE,
        "error in generateNewToken",
        err.toString(),
        "generateNewToken",
      );
      this.logger.log(body);
    }
    return idToken;
  }

  ///generate new token
  async refreshToken()
  {
    let idToken = null;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("refreshToken");

      idToken = await FirebaseAuthentication.getIdToken(true);
    }
    catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.USER_AUTHENTICATION_SERVICE,
        "error in refreshToken",
        err.toString(),
        "refreshToken",
      );
      this.logger.log(body);
    }
    return idToken;
  }

}
