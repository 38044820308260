import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { BillDetailModel } from "src/app/model/bill.model";
import { BackupAttempt, BackupBill, BackupBillsLs, BackupPaidBillsLs, BackupProcess, BillDetail } from "./bill.action";

export const initialBillDetailState : BillDetailModel = {
  selectedBillDetail : null,
  isBackupBillAdded : false,
  backupBillsLs : null,
  attemptCount: 1,
  backupPaidBillsLs: null,
  initiateBackup: true,
}

export const BILL_DETAIL = new StateToken<BillDetailModel>('BillDetail');

@State({
 name : BILL_DETAIL ,
 defaults : initialBillDetailState
})

@Injectable()
export class BillDetailState 
{
 constructor() {}

 @Action(BillDetail)
  saveBillDetail(ctx : StateContext<BillDetailModel>, action : BillDetail)
  {
    ctx.patchState({ selectedBillDetail : action.billDetail })
  }

  @Action(BackupBill)
  saveBackupBill(ctx : StateContext<BillDetailModel>, action : BackupBill)
  {
    ctx.patchState({ isBackupBillAdded : action.backupBill })
  }

  @Action(BackupBillsLs)
  saveBackupBillsLs(ctx : StateContext<BillDetailModel>, action : BackupBillsLs)
  {
    ctx.patchState({ backupBillsLs : action.backupBillsLs })
  }

  @Action(BackupAttempt)
  saveBackupAttempt(ctx : StateContext<BillDetailModel>, action : BackupAttempt)
  {
    ctx.patchState({ attemptCount : action.backupAttempt })
  }

  @Action(BackupPaidBillsLs)
  saveBackupPaidBillsLs(ctx : StateContext<BillDetailModel>, action : BackupPaidBillsLs)
  {
    ctx.patchState({ backupPaidBillsLs : action.backupPaidBillsLs })
  }

  @Action(BackupProcess)
  saveBackupProcess(ctx : StateContext<BillDetailModel>, action : BackupProcess)
  {
    ctx.patchState({ initiateBackup : action.backupProcess })
  }
}